import React, { PureComponent, Component } from 'react'
import './index.css'
import Typed from 'typed.js'

export default class TypedReactText extends React.Component {
    componentDidMount() {
        const { strings } = this.props;
        const options = {
            strings: strings,
            typeSpeed: 75,
            backSpeed: 75,
            startDelay: 300,
            shuffle: false,
            backDelay: this.props.backDelay ? this.props.backDelay : 3000,
            loop: true,
            loopCount: false,
            showCursor: true,
            cursorChar: this.props.cursorChar ? this.props.cursorChar : '|',
        };
        this.typed = new Typed(this.el, options);
    }
  
    componentWillUnmount() {
      this.typed.destroy();
    }
  
    render() {
      return (
        <div className="divTyped" 
        style={{
          fontSize: this.props.fontSizeCursor ? this.props.fontSizeCursor : "7vw", 
          color: this.props.colorCursor ? this.props.colorCursor : "#1D3772",
        }}>
            <span
              style={{ whiteSpace: 'break-spaces' }}
              ref={(el) => { this.el = el; }}
              className={this.props.classNameTyped}
            />
        </div>
      );
    }
}