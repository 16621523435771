import React, { PureComponent, Component } from 'react'
import './index.css'
import history from '../../history'
import NuestrosClientes from '../NuestrosClientes/index'
import NuestroBlog from '../InscribeteBlog/index'
import BotonGral from '../BotonGral/index'
import TarjetitasHistoriasExitoClientes from '../HistoriasExitoClientes/index'
import BannerCOVID from '../BannerCOVID/index'
import ThinkcareSection from '../ThinkcareSection/bannerActualizado'
import TodosLosServicios from '../AnimTodosLosServicios/index'
import playvideo from './img/play.svg'
import TypedReactText from '../AnimTyped/index'
import {Helmet} from "react-helmet";

export default class Home extends Component {
    render() {
        return (
            <>
            <Helmet>
                <title>BB&IT Enterprise Services / Servicios de IT y Soluciones para Procesos de Negocio</title>
                <meta name="description" content="Ofrecemos herramientas, buena práctica de procesos y talento capaz de integrar soluciones. Somos expertos en software empresarial." />
            </Helmet>
                <div className="sectionHome-desk">
                    <div className="container-sections">
                        <div className="box-bienvenidahome">
                            <div className="txt-bienvenidahome">
                                <TypedReactText
                                    strings={[
                                        'Desarrollamos soluciones',
                                        'Potenciamos ideas',
                                        'Aumentamos opciones',
                                        'Creamos tecnología',
                                        'Activamos el futuro',
                                        'Aceleramos resultados',
                                        'Provocamos cambios',
                                        'Ordenamos datos',
                                        'Diseñamos herramientas',
                                        'Tenemos experiencia',
                                        'Formamos talentos',
                                        'Mejoramos negocios'
                                    ]}
                                    classNameTyped="title-sections"
                                />
                                <h1><p className="title2-sections" style={{ marginBottom: "3vw" }}>Planeación y ejecución de estrategias tecnológicas.</p></h1>
                                <p className="title3-sections">Ofrecemos herramientas, buena práctica de procesos y talento capaz de integrar soluciones. <a style={{fontFamily:"Roboto-Black"}}>Somos expertos en software empresarial</a>.</p>
                            </div>

                        </div>

                        <div>
                            <BotonGral marginRight="5%" texto="Arma tu solución" />
                            <BotonGral className="formato-btngral-azulbrilloso" color="#0086FC" marginRight="5%" texto="Lo que ofrecemos" onClick={() => { window.scrollTo(0, 0); history.push("/servicios") }} />
                            {/*<div className="txt-vervideo-home">
                                <img className="icon-vervideo-home cursor" src={playvideo} />
                                Ver vídeo
                            </div>*/}
                        </div>

                        <div style={{ width: "100%", height: "5vw" }} />

                        {/*<BannerCOVID />*/}

                        <h2><p className="subtitle-sections2" >Transformación digital.</p></h2>
                        <div className="content-sections" style={{ marginBottom: "2vw" }} >Con más de 20 años de experiencia, implementamos tecnología que te lleva a donde quieres llegar. Contamos con tecnología dirigida a tu empresa.</div>
                    </div>

                    <TodosLosServicios />

                    <NuestrosClientes />

                    {/*<div style={{padding:"2% 6% 1%"}} >
                        <div style={{ padding: "0 0 3%" }}>
                            <h2 className="subtitle-sections2" style={{ marginBottom: "1.5vw" }}>Historias de éxito</h2>
                            <p className="content-sections">
                                A lo largo de los años, hemos construido e implementado con éxito una gama de excelentes productos de software para varios clientes de México y Latinoamérica. Todos los casos se entregaron diligentemente, a tiempo según las especificaciones y de gran calidad.
                            </p>
                        </div>
                        <TarjetitasHistoriasExitoClientes valueindex="2" />

                        <BotonGral className="formato-btngral-azulbrilloso" texto="Ver Proyectos" marginLeft="2%" onClick={() => { window.scrollTo(0, 0); history.push("/proyectos") }} />

                    </div>*/}

                    <ThinkcareSection />

                    <NuestroBlog />
                </div>

                <div className="sectionHome-mobile">
                <div className="container-sections">
                        <div className="box-bienvenidahome">
                            <div className="txt-bienvenidahome">
                                <TypedReactText
                                        strings={[
                                            'Desarrollamos soluciones',
                                            'Potenciamos ideas',
                                            'Aumentamos opciones',
                                            'Creamos tecnología',
                                            'Activamos el futuro',
                                            'Aceleramos resultados',
                                            'Provocamos cambios',
                                            'Ordenamos datos',
                                            'Diseñamos herramientas',
                                            'Tenemos experiencia',
                                            'Formamos talentos',
                                            'Mejoramos negocios'
                                        ]}
                                        classNameTyped="title-sections"
                                        fontSizeCursor="11vw"
                                    />

                                    <p className="title2-sections" style={{ marginBottom: "7vw" }}>Experiencia en planeación y ejecución de estrategias tecnológicas.</p>
                                    <p className="title3-sections" style={{ marginBottom: "7vw" }}>Ofrecemos herramientas, buena práctica de procesos y talento capaz de integrar soluciones.<br/> Somos expertos en software empresarial.</p>
                            </div>

                        </div>

                        <div style={{display:"flex", justifyContent:"space-around"}}>
                            <BotonGral marginRight="5%" texto="Arma tu solución" fontSize="3.8vw" />
                            <BotonGral className="formato-btngral-azulbrilloso" fontSize="3.8vw" color="#0086FC" marginRight="5%" texto="Lo que ofrecemos" onClick={() => { window.scrollTo(0, 0); history.push("/nosotros") }} />
                        </div>

                        <div style={{ width: "100%", height: "8vw" }} />

                        {/*<BannerCOVID />*/}

                        <p className="subtitle-sections2" >Experiencia en planeación y ejecución de estrategias tecnológicas.</p>
                    </div>

                    <TodosLosServicios />

                    <NuestrosClientes />

                    {/*<div style={{padding:"2% 6% 1%"}} >
                        <div style={{ padding: "0 0 3%" }}>
                            <h2 className="subtitle-sections2" style={{ marginBottom: "1.5vw" }}>Historias de éxito</h2>
                            <p className="content-sections">
                                A lo largo de los años, hemos construido e implementado con éxito una gama de excelentes productos de software para varios clientes de México y Latinoamérica. Todos los casos se entregaron diligentemente, a tiempo según las especificaciones y de gran calidad.
                            </p>
                        </div>
                        <TarjetitasHistoriasExitoClientes valueindex="2" />

                        <BotonGral className="formato-btngral-azulbrilloso" texto="Ver Proyectos" marginLeft="2%" onClick={() => { window.scrollTo(0, 0); history.push("/proyectos") }} />

                    </div>*/}

                    <ThinkcareSection />

                    <NuestroBlog />
                </div>

            </>

        )
    }
}