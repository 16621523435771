import React, { PureComponent } from 'react'
import './index.css'
import history from '../../history'
import facebook from './img/facebook.svg'
import instagram from './img/instagram.svg'
import twitter from './img/twitter.svg'
import linkedIn from './img/linkedIn.svg'
import menuServicios from './img/menu-servicios.svg'
import cerrarServicios from './img/cerrar-servicios.svg'
import flechitasig from './img/flechitaFooter.svg'

export default class Footer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            openServicios: false,
        }

    }

    mostrarServicios = () => {
        this.setState({
            openServicios: !this.state.openServicios
        })
    }

    render() {
        return (
            <>
                <div className="componentFooter-desk">
                    <div className="footer">
                        <div className="box-footer">
                            <div className="div-icons">

                                <div className="txt-servicios-footer">
                                    <div className="div-icon-menu-servicios">
                                        <img src={this.state.openServicios ? cerrarServicios : menuServicios} onClick={() => this.mostrarServicios()} className="icon-menu-servicios cursor" />
                                    </div>
                                    <div style={{ display: "inline-block" }}>{this.state.openServicios ? "CERRAR" : "SERVICIOS"}</div>
                                </div>

                                <div className="div-txtfooter">
                                    <p><a style={{ fontFamily: "Roboto-Bold" }}>BBP 2020</a>. Todos los derechos reservados</p>
                                    <div>
                                        <p className="cursor">Aviso de privacidad</p> | <p className="cursor" style={{ fontFamily: "Roboto-Bold" }}>info@bbpingod.com</p>
                                    </div>
                                </div>

                                <div style={{ position: "relative", top: "20%" }}>
                                    <img className="icons-footer-efect" src={linkedIn} onClick={(e) => { window.open('https://www.linkedin.com/company/bbp-it/', ' "_blank"') }} style={{ width: "2.5vw" }} />
                                    <img className="icons-footer-efect" src={facebook} onClick={(e) => { window.open('https://www.facebook.com/bbpits/', ' "_blank"') }} style={{ width: "1.1vw", marginLeft: "2vw" }} />
                                    <img className="icons-footer-efect" src={twitter} onClick={(e) => { window.open('https://twitter.com/bbpits?lang=es', ' "_blank"') }} style={{ width: "2.6vw", margin: "0 2.5vw 0 3.5vw" }} />
                                    <img className="icons-footer-efect" src={instagram} onClick={(e) => { window.open('https://www.instagram.com/bbpits_/', ' "_blank"') }} style={{ width: "2.4vw" }} />
                                </div>
                            </div>

                            {this.state.openServicios ?
                                <div className="div-servicios">
                                    <div style={{ width: "12%" }}>
                                        <div className="divnfs" style={{ width: "9.2vw" }} onClick={() => { this.mostrarServicios(); window.scrollTo(0, 0); history.push("/detalleservicios/Estrategia") }}>Estrategia <img className="fs" src={flechitasig} /> </div>
                                        <p>• Empresarial</p>
                                        <p>• Tecnología</p>
                                    </div>

                                    <div style={{ width: "28%" }}>
                                        <div className="divnfs" style={{ width: "20vw" }} onClick={() => { this.mostrarServicios(); window.scrollTo(0, 0); history.push("/detalleservicios/Arquitectura-de-procesos") }}>Arquitectura de procesos <img className="fs" src={flechitasig} /> </div>
                                        <p>• Enterprise Resource Planning (ERP)</p>
                                        <p>• Customer Relationship Management (CRM)</p>
                                        <p>• Businnes Inteligence (BI)</p>
                                        <p>• Gobierno de datos</p>
                                        <p>• Centro de competencias de Inteligencia de negocios (CCIN)</p>
                                    </div>

                                    <div style={{ width: "22%" }}>
                                        <div className="divnfs" style={{ width: "18.5vw" }} onClick={() => { this.mostrarServicios(); window.scrollTo(0, 0); history.push("/detalleservicios/Software-y-Extensiones") }}>Software y extensiones <img className="fs" src={flechitasig} /> </div>
                                        <p>• Equipo de desarrollo</p>
                                        <p>• Software corporativo</p>
                                    </div>

                                    <div style={{ width: "28%" }}>
                                        <div className="divnfs" style={{ width: "16.5vw" }} onClick={() => { this.mostrarServicios(); window.scrollTo(0, 0); history.push("/detalleservicios/Servicios-Nube") }}>Servicios en la nube <img className="fs" src={flechitasig} /> </div>
                                        <p>• Transportation Management System (TMS)</p>
                                        <p>• Enterprise Application Integration (EAI)</p>
                                        <p>• Internet of things (IOT)</p>
                                        <p>• Cómputo</p>
                                        <p>• Bases de datos</p>
                                        <p>• Plan informático contra desastres</p>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>

                <div className="componentFooter-mobile">
                    <div className="footer">
                        <div className="box-footer">
                            <div className="div-icons">

                                <div className="txt-servicios-footer">
                                    <div className="div-icon-menu-servicios">
                                        <img src={this.state.openServicios ? cerrarServicios : menuServicios} onClick={() => this.mostrarServicios()} className="icon-menu-servicios cursor" />
                                    </div>
                                    <div style={{ display: "inline-block" }}>{this.state.openServicios ? "CERRAR" : "SERVICIOS"}</div>
                                </div>

                                <div className="div-txtfooter">
                                    <p><a style={{ fontFamily: "Roboto-Bold" }}>BBP 2020</a>. Todos los derechos reservados</p>
                                    <div>
                                        <p className="cursor">Aviso de privacidad</p> | <p className="cursor" style={{ fontFamily: "Roboto-Bold" }}>info@bbpingod.com</p>
                                    </div>
                                </div>

                                <div style={{ position: "relative", top: "20%" }}>
                                    <img className="icons-footer-efect" src={linkedIn} onClick={(e) => { window.open('https://www.linkedin.com/company/bbp-it/', ' "_blank"') }} style={{ width: "5.5vw" }} />
                                    <img className="icons-footer-efect" src={facebook} onClick={(e) => { window.open('https://www.facebook.com/bbpits/', ' "_blank"') }} style={{ width: "2.6vw", marginLeft: "13vw" }} />
                                    <img className="icons-footer-efect" src={twitter} onClick={(e) => { window.open('https://twitter.com/bbpits?lang=es', ' "_blank"') }} style={{ width: "5.6vw", margin: "0 13.5vw 0 14.5vw" }} />
                                    <img className="icons-footer-efect" src={instagram} onClick={(e) => { window.open('https://www.instagram.com/bbpits_/', ' "_blank"') }} style={{ width: "5.4vw" }} />
                                </div>
                            </div>

                            {this.state.openServicios ?
                                <div className="div-servicios">
                                    <div style={{ width: "100%" }}>
                                        <div className="divnfs" style={{ width: "21.2vw" }} onClick={() => { this.mostrarServicios(); window.scrollTo(0, 0); history.push("/detalleservicios/Estrategia") }}>Estrategia <img className="fs" src={flechitasig} /> </div>
                                        <p>• Empresarial</p>
                                        <p>• Tecnología</p>
                                    </div>

                                    <div style={{ width: "100%" }}>
                                        <div className="divnfs" style={{ width: "45vw" }} onClick={() => { this.mostrarServicios(); window.scrollTo(0, 0); history.push("/detalleservicios/Arquitectura-de-procesos") }}>Arquitectura de procesos <img className="fs" src={flechitasig} /> </div>
                                        <p>• Enterprise Resource Planning (ERP)</p>
                                        <p>• Customer Relationship Management (CRM)</p>
                                        <p>• Businnes Inteligence (BI)</p>
                                        <p>• Gobierno de datos</p>
                                        <p>• Centro de competencias de Inteligencia de negocios (CCIN)</p>
                                    </div>

                                    <div style={{ width: "100%" }}>
                                        <div className="divnfs" style={{ width: "42.5vw" }} onClick={() => { this.mostrarServicios(); window.scrollTo(0, 0); history.push("/detalleservicios/Software-y-Extensiones") }}>Software y extensiones <img className="fs" src={flechitasig} /> </div>
                                        <p>• Equipo de desarrollo</p>
                                        <p>• Software corporativo</p>
                                    </div>

                                    <div style={{ width: "100%" }}>
                                        <div className="divnfs" style={{ width: "37vw" }} onClick={() => { this.mostrarServicios(); window.scrollTo(0, 0); history.push("/detalleservicios/Servicios-Nube") }}>Servicios en la nube <img className="fs" src={flechitasig} /> </div>
                                        <p>• Transportation Management System (TMS)</p>
                                        <p>• Enterprise Application Integration (EAI)</p>
                                        <p>• Internet of things (IOT)</p>
                                        <p>• Cómputo</p>
                                        <p>• Bases de datos</p>
                                        <p>• Plan informático contra desastres</p>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}