import React, { PureComponent } from 'react'
import './index.css'
import history from '../../history'
import NuestroBlog from '../InscribeteBlog/index'
import NuestrosClientes from '../NuestrosClientes/index'
import TarjetitasHistoriasExitoClientes from '../HistoriasExitoClientes/index'
import ThinkcareSection from '../ThinkcareSection/bannerActualizado'
import PotenciamosIdeas from '../PotenciamosIdeas/index'
import BotonGral from '../BotonGral/index'
import TypedReactText from '../AnimTyped/index'
import {Helmet} from "react-helmet";

export default class Servicios extends PureComponent {

    render() {
        return (
            <>
                <Helmet>
                    <title>BB&IT Enterprise Services / Servicios de IT y Soluciones para Procesos de Negocio</title>
                    <meta name="description" content="Con más de 20 años de experiencia en desarrollo de software personalizado, ofrecemos servicios de desarrollo de software para empresas, grandes y pequeñas." />
                </Helmet>
                <div className="sectionServicios-desk">
                    <div className="container-sections">
                        <div className="intro-servicios">
                            <h1><TypedReactText
                                strings={[
                                    'Sinapsis Empresarial',
                                    'Transformación Digital'
                                ]}
                                classNameTyped="title-sections"
                            /></h1>

                            <p className="title3-sections" style={{ width: "82%" }}><a style={{ fontFamily: "Roboto-Black" }}>Garantizamos resultados</a>. Desde la identificación de los ciclos de trabajo hasta la integración de todas las soluciones digitales a tu alcance para generar sistemas y lograr las mejores prácticas en el mercado.</p>
                        </div>
                    </div>

                    <div style={{ padding: "0 0 0 6%" }}>
                        <div style={{ float: "left", width: "80%" }}>
                            <PotenciamosIdeas />
                        </div>
                        <div className="div-contenedor-agendarcita">
                            <div style={{position:"sticky", top:"35%"}}>
                                <div className="boton-agendarcita" />
                                <div className="contenedor-btngral-agendarcita-serv">
                                    <BotonGral className="formato-btngral-asesoria" texto="Asesoría personalizada" color="#ffff" fontSize="1.9vw" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ width: "100vw", display: "inline-block" }} />
                    <NuestrosClientes />

                    {/*<div style={{padding:"2% 6% 1%"}} >
                        <div style={{ padding: "0 0 3%" }}>
                            <h2 className="subtitle-sections" style={{ marginBottom: "1.5vw" }}>Historias de éxito</h2>
                            <p className="content-sections">
                                A lo largo de los años, hemos construido e implementado con éxito una gama de excelentes productos de software para varios clientes de México y Latinoamérica. Todos los casos se entregaron diligentemente, a tiempo según las especificaciones y de gran calidad.
                            </p>
                        </div>
                        <TarjetitasHistoriasExitoClientes valueindex="2" />

                        <BotonGral className="formato-btngral-verde" texto="Ver Proyectos" marginLeft="2%" onClick={() => { window.scrollTo(0, 0); history.push("/proyectos") }} />

                    </div>*/}

                    <ThinkcareSection />
                    <NuestroBlog />
                </div>

                <div className="sectionServicios-mobile">
                    <div className="container-sections">
                        <div className="intro-servicios">
                            <TypedReactText
                                strings={[
                                    'Sinapsis Empresarial',
                                    'Transformación Digital'
                                ]}
                                classNameTyped="title-sections"
                                fontSizeCursor="11vw"
                            />

                            <p className="title3-sections" style={{ width: "100%" }}><a style={{ fontFamily: "Roboto-Black" }}>Garantizamos resultados</a>. Desde la identificación de los ciclos de trabajo hasta la integración de todas las soluciones digitales a tu alcance para generar sistemas y lograr las mejores prácticas en el mercado.</p>
                        </div>
                    </div>

                    <div style={{ padding: "0 0 0 6%" }}>
                        <div style={{ width: "100%" }}>
                            <PotenciamosIdeas />
                        </div>
                    </div>

                    <div className="divBtnGral-mobile">
                        <BotonGral className="formato-btngral-asesoria" texto="Asesoría personalizada" color="#122E5F" fontSize="1.75rem" />
                    </div>

                    <div style={{ width: "100vw", display: "inline-block" }} />
                    <NuestrosClientes />

                    {/*<div style={{padding:"2% 6% 1%"}} >
                        <div style={{ padding: "0 0 3%" }}>
                            <h2 className="subtitle-sections" style={{ marginBottom: "1.5vw" }}>Historias de éxito</h2>
                            <p className="content-sections">
                                A lo largo de los años, hemos construido e implementado con éxito una gama de excelentes productos de software para varios clientes de México y Latinoamérica. Todos los casos se entregaron diligentemente, a tiempo según las especificaciones y de gran calidad.
                            </p>
                        </div>
                        <TarjetitasHistoriasExitoClientes valueindex="2" />

                        <BotonGral className="formato-btngral-verde" texto="Ver Proyectos" marginLeft="2%" onClick={() => { window.scrollTo(0, 0); history.push("/proyectos") }} />

                    </div>*/}

                    <ThinkcareSection />
                    <NuestroBlog />
                </div>
            </>
        )
    }
}