import React, { PureComponent } from 'react'
import './index.css'
import bbpologo from "../Contacto/img/bbp.svg"
import map from "../Contacto/img/map.png"
import BotonGral from '../BotonGral/index'
import Typing from 'react-typing-animation';
import axios from 'axios';
import BotonContactanos from '../BotonContactanos/index'
import TypedReactText from '../AnimTyped/index'
import {Helmet} from "react-helmet";

class Contacto extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            showSaveButtons: false,
            datosFormulario: {
                nombre: "",
                personas: "",
                correo: "",
                sitio_web: "",
                telefono: "",
                descripcion: "",
                industria: "",
                empresa: "",
            },
            formularioValidado: {
                nombre: false,
                correo: false,
                descripcion: false,
                empresa: false,
                telefono: false,
            },
            correoInvalido: false,
            nombreInvalido: false,
            telefonoInvalido: false,
            empresaInvalido: false,


        }

        // Referencias Formulario
        this.refFormNombre = React.createRef();
        this.refFormCorreo = React.createRef();
        this.refFormTelefono = React.createRef();
        this.refFormEmpresa = React.createRef();
        this.refFormDescripcion = React.createRef();
        this.refFormSitioWeb = React.createRef();


    }




    handleValidateNombre = value => {
        const valido = value.length > 1;

        return valido;
    }

    handleValidateEmpresa = value => {
        const valido = value.length > 2;

        return valido;
    }

    handleValidateCorreo = value => {
        const valido = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value));

        return valido;
    }

    handleValidateTelefono = value => {
        const valido = (/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/.test(value));

        return valido;
    }

    handleValidateDescipion = value => {
        const valido = value.length > 1;

        return valido;
    }

    handleShowSaveBtn = () => {
        const { nombre, correo, descripcion, telefono, empresa } = this.state.formularioValidado;
        const showSaveButtons = nombre && correo && descripcion && telefono && empresa;
        this.setState({ showSaveButtons })

        return 0;
    }




    handleValidateForm = params => {
        const { name, value } = params;
        let valido = false;
        // eslint-disable-next-line default-case
        switch (name) {
            case 'nombre':
                valido = this.handleValidateNombre(value);
                this.setState({ nombreInvalido: !valido });
                break;

            case 'correo':
                valido = this.handleValidateCorreo(value);
                this.setState({ correoInvalido: !valido });
                break;

            case 'descripcion':
                valido = this.handleValidateDescipion(value);
                break;

            case 'telefono':
                valido = this.handleValidateTelefono(value);
                this.setState({ telefonoInvalido: !valido });
                break;


            case 'empresa':
                valido = this.handleValidateEmpresa(value);
                this.setState({ empresaInvalido: !valido });
                break;


        }
        console.log(valido);
        this.setState(prevState => (
            {
                formularioValidado: {
                    ...prevState.formularioValidado,
                    [name]: valido
                }
            }
        ), this.handleShowSaveBtn);


    }

    handleChangeInputs = event => {
        const { name, value } = event.target;
        this.setState(prevState => (
            {
                datosFormulario: {
                    ...prevState.datosFormulario,
                    [name]: value
                }
            }
        ), () => { this.handleValidateForm({ name, value }) });
    }

    handleRegistroProyecto = async () => {
        this.resetForm();
        alert("Muchas Gracias Nosotros Nos pondremos en contacto");
        const resp = await axios.post("https://app-bbp-sitio-marca-gateway-prod.azurewebsites.net/api/cuentanos_proyectos", this.state.datosFormulario)
        console.log("esto respondio el servicio", resp.data)

    }

    resetForm = () => {
        this.refFormNombre.current.value = ""
        this.refFormCorreo.current.value = ""
        this.refFormTelefono.current.value = ""
        this.refFormEmpresa.current.value = ""
        this.refFormDescripcion.current.value = ""
        this.refFormSitioWeb.current.value = ""
    }

    render() {

        const estilosCorreo = {

            borderColor: "red",

        }

        const estilosNombre = {

            borderColor: "red",

        }

        const estilosTelefono = {

            borderColor: "red",

        }

        const estilosEmpresa = {

            borderColor: "red",

        }


        return (
            <>
                <Helmet>
                    <title>BB&IT Enterprise Services / Servicios de IT y Soluciones para Procesos de Negocio</title>
                    <meta name="description" content="Con más de 20 años de experiencia en desarrollo de software personalizado, ofrecemos servicios de desarrollo de software para empresas, grandes y pequeñas." />
                </Helmet>

                <div className="sectionContacto-desk">

                    <div className="contacto-container" >
                        <div style={{ padding: "0vw 20vw 0vw 0vw" }}>
                            <h1><TypedReactText
                                strings={[
                                    'Creamos soluciones para empresas de todo el mundo.'
                                ]}
                                classNameTyped="title-sections"
                                backDelay="30000"
                            /></h1>
                        </div>

                        <div className="formularios">
                            <h2><p className="subtitle-sections" style={{ marginBottom: "0" }}>Cuéntanos sobre ti y tu proyecto</p></h2>
                            <form className="contactano-form">
                                <div className="formularios">
                                    <input ref={this.refFormNombre} onChange={this.handleChangeInputs} style={this.state.nombreInvalido ? estilosNombre : {}} className="contacto-formulario" type="text" name="nombre" required autocomplete="off" placeholder="Nombre Completo" />
                                    <input ref={this.refFormCorreo} onChange={this.handleChangeInputs} style={this.state.correoInvalido ? estilosCorreo : {}} className="contacto-formulario" type="email" name="correo" required autocomplete="off" placeholder="Email de Contacto" />

                                </div>
                                <div className="formularios">
                                    <input ref={this.refFormTelefono} onChange={this.handleChangeInputs} style={this.state.telefonoInvalido ? estilosTelefono : {}} className="contacto-formulario" type="tel" name="telefono" required autocomplete="off" placeholder="Teléfono" />
                                    <input ref={this.refFormEmpresa} onChange={this.handleChangeInputs} style={this.state.empresaInvalido ? estilosEmpresa : {}} className="contacto-formulario" type="text" name="empresa" required autocomplete="off" placeholder="Nombre de la empresa" />
                                </div>
                                <div className="formularios">
                                    <input ref={this.refFormSitioWeb} onChange={this.handleChangeInputs} className="contacto-formulario" type="text" name="sitio_web" autocomplete="off" placeholder="Sitio Web" />


                                    <select onChange={this.handleChangeInputs} style={{ color: "#7c8084" }} className="contacto-formulario" name="personas" >
                                        <option hidden selected>¿Cuántas personas trabajan?</option>
                                        <option value="1 a 5">1 a 5</option>
                                        <option value="6 a 10">6 a 10</option>
                                        <option value="11 a 25">11 a 25</option>
                                        <option value="26 a 50">26 a 50</option>
                                        <option value="51 a 100">51 a 100</option>
                                        <option value="101 a 200">101 a 200</option>
                                        <option value="201 a 1000">201 a 1000</option>
                                    </select>
                                </div>
                                <div className="formularios">
                                    <select onChange={this.handleChangeInputs} style={{ color: "#7c8084", width: "55%" }} className="contacto-formulario" name="industria">
                                        <option hidden selected>¿Cual es el tipo de industria de tu empresa?</option>
                                        <option value="Agrícola">Agrícola</option>
                                        <option value="Alimentos y Bebidas">Alimentos y Bebidas.</option>
                                        <option value="Automotriz">Automotriz</option>
                                        <option value="Bienes de consumo">Bienes de consumo</option>
                                        <option value="Educación">Comercial</option>
                                        <option value="Farmacéutica">Farmacéutica</option>
                                        <option value="Filantropía">Filantropía</option>
                                        <option value="Servicios">Servicios</option>
                                    </select>
                                </div>
                                <div className="formularios">
                                    <textarea ref={this.refFormDescripcion} onChange={this.handleChangeInputs} className="contacto-servicios" style={{ width: "55%", marginTop: "2vw", padding: "2% 1.5%" }} name="descripcion" rows="4" cols="40" placeholder="Detalle del Proyecto"></textarea>

                                </div>
                                <div style={{ marginBottom: "2vw" }}>
                                    {
                                        this.state.showSaveButtons ?
                                            (
                                                <BotonGral className="formato-btngral-azulEnviar" onClick={this.handleRegistroProyecto} marginTop="3vw" marginLeft="2%" texto="Enviar" />
                                            ) :
                                            (null)
                                    }
                                </div>


                            </form>
                        </div>

                        <div style={{ display: "flex", marginTop: "5vw" }} className="panel">
                            <h3 style={{ width: "65%" }}><div className="subtitle-sections2" style={{ width: "100%" }}>Recibe la atención personalizada de nuestros expertos.</div></h3>
                            <div>
                                <BotonGral className="formato-btngral-azul" marginLeft="1vw" fontFamily="Roboto-Bold" texto="Agenda una cita" />
                            </div>
                        </div>

                        <div className="container-ubicacion">
                            <div className="logo-bbpmap">
                                <img style={{ width: "10vw" }} src={bbpologo} />
                            </div>


                            <div className="info-container">
                                <div className="ubicacion-map">
                                    <iframe
                                        className="mapa"
                                        title="Nuestra Ubicacion"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3765.4886567863623!2d-99.20550678509188!3d19.304590749763825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cdffc4f345b063%3A0x17b6ac829383a1a7!2sPeriferico+Sur+4293%2C+Jardines+del+Pedregal%2C+14140+Ciudad+de+M%C3%A9xico%2C+CDMX!5e0!3m2!1ses-419!2smx!4v1552340875343"
                                    ></iframe>
                                </div>

                                <div className="llamanos">
                                    <div className="direccion-txt">Periferico Sur 4293, Jardines del Pedregal, Tlalpan, C.P. 14140 Ciudad de México, CDMX</div>
                                    <div className="btn-llamar">
                                        <BotonContactanos />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >


                </div>

                <div className="sectionContacto-mob" >
                    <div className="contacto-containerMob" >
                        <div>
                            <TypedReactText
                                strings={[
                                    'Creamos soluciones para empresas de todo el mundo.'
                                ]}
                                classNameTyped="title-sections"
                                fontSizeCursor="11vw"
                                backDelay="30000"
                            />
                        </div>

                        <div className="formularios">
                            <p className="subtitle-sections" style={{ marginBottom: "0", width:"100%" }}>Cuéntanos sobre ti y tu proyecto</p>
                            <form className="contactano-form">
                                <div className="formularios">
                                    <input ref={this.refFormNombre} onChange={this.handleChangeInputs} style={this.state.nombreInvalido ? estilosNombre : {}} className="contacto-formulario" type="text" name="nombre" required autocomplete="off" placeholder="Nombre Completo" />
                                </div>
                                <div className="formularios">
                                    <input ref={this.refFormCorreo} onChange={this.handleChangeInputs} style={this.state.correoInvalido ? estilosCorreo : {}} className="contacto-formulario" type="email" name="correo" required autocomplete="off" placeholder="Email de Contacto" />
                                </div>
                                <div className="formularios">
                                    <input ref={this.refFormTelefono} onChange={this.handleChangeInputs} style={this.state.telefonoInvalido ? estilosTelefono : {}} className="contacto-formulario" type="tel" name="telefono" required autocomplete="off" placeholder="Teléfono" />
                                </div>
                                <div className="formularios">
                                    <input ref={this.refFormEmpresa} onChange={this.handleChangeInputs} style={this.state.empresaInvalido ? estilosEmpresa : {}} className="contacto-formulario" type="text" name="empresa" required autocomplete="off" placeholder="Nombre de la empresa" />
                                </div>
                                <div className="formularios">
                                    <input ref={this.refFormSitioWeb} onChange={this.handleChangeInputs} className="contacto-formulario" type="text" name="sitio_web" autocomplete="off" placeholder="Sitio Web" />
                                </div>
                                <div className="formularios">
                                    <select onChange={this.handleChangeInputs} style={{ color: "#7c8084" }} className="contacto-formulario" name="personas" >
                                        <option hidden selected>¿Cuántas personas trabajan?</option>
                                        <option value="1 a 5">1 a 5</option>
                                        <option value="6 a 10">6 a 10</option>
                                        <option value="11 a 25">11 a 25</option>
                                        <option value="26 a 50">26 a 50</option>
                                        <option value="51 a 100">51 a 100</option>
                                        <option value="101 a 200">101 a 200</option>
                                        <option value="201 a 1000">201 a 1000</option>
                                    </select>                        </div>
                                <div className="formularios">
                                    <select onChange={this.handleChangeInputs} style={{ color: "#7c8084" }} className="contacto-formulario" name="industria">
                                        <option hidden selected>¿Cual es el tipo de industria de tu empresa?</option>
                                        <option value="Agrícola">Agrícola</option>
                                        <option value="Alimentos y Bebidas">Alimentos y Bebidas.</option>
                                        <option value="Automotriz">Automotriz</option>
                                        <option value="Bienes de consumo">Bienes de consumo</option>
                                        <option value="Educación">Comercial</option>
                                        <option value="Farmacéutica">Farmacéutica</option>
                                        <option value="Filantropía">Filantropía</option>
                                        <option value="Servicios">Servicios</option>
                                    </select>
                                </div>
                                <div className="formularios">
                                    <textarea ref={this.refFormDescripcion} onChange={this.handleChangeInputs} className="textarea-servicios" name="descripcion" rows="4" cols="40" placeholder="Detalle del Proyecto"></textarea>

                                </div>
                                <div style={{ marginBottom: "2vw" }}>
                                    {
                                        this.state.showSaveButtons ?
                                            (
                                                <BotonGral className="formato-btngral-azulEnviar" onClick={this.handleRegistroProyecto} marginTop="3vw" marginLeft="2%" texto="Enviar" />
                                            ) :
                                            (null)
                                    }
                                </div>


                            </form>
                        </div>

                        <div className="panel">
                            <div className="atencion">Recibe la atención personalizada de nuestros expertos.</div>
                            <div>
                                <BotonGral className="formato-btngral-azulEnviar" marginTop="4vw" fontSize="3.5vw" marginLeft="3vw" texto="Agenda Una  Cita" />
                            </div>
                        </div>

                        <div className="container-ubicacion">
                            <div className="logo-bbpmap">
                                <img style={{ width: "19vw" }} src={bbpologo} />
                            </div>


                            <div className="info-containerM">
                                <div className="ubicacion-map">
                                    <iframe
                                        className="mapa"
                                        title="Nuestra Ubicacion"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3765.4886567863623!2d-99.20550678509188!3d19.304590749763825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cdffc4f345b063%3A0x17b6ac829383a1a7!2sPeriferico+Sur+4293%2C+Jardines+del+Pedregal%2C+14140+Ciudad+de+M%C3%A9xico%2C+CDMX!5e0!3m2!1ses-419!2smx!4v1552340875343"
                                    ></iframe>
                                </div>


                            </div>
                            <div className="llamanos">
                                <div className="direccion-txt">Periferico Sur 4293, Jardines del Pedregal, Tlalpan, C.P. 14140 Ciudad de México, CDMX</div>
                                <div className="btn-llamar">
                                    <BotonContactanos fontSize="3.5vw" />
                                </div>
                            </div>
                        </div>

                    </div >

                </div>


            </>
        )

    }

} export default (Contacto)

