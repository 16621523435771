import React, { PureComponent } from 'react'
import './index.css'
import img1 from '../SoftExtServ/img/img1.png'
import img2 from '../SoftExtServ/img/img2.png'
import principal from '../SoftExtServ/img/principal.svg'
// import img4 from '../SoftExtServ/img/principal.svg'
import NuestroBlog from '../InscribeteBlog/index'
import history from '../../history'
import BotonGral from '../BotonGral/index'
import AnimSVG from '../AnimSVG/index'
import Dropdown from 'react-bootstrap/Dropdown'
import FormularioRegistro from '../FormularioRegistro/index'
import ThinkcareSection from '../ThinkcareSection/bannerActualizado'
import TypedReactText from '../AnimTyped/index'
import {Helmet} from "react-helmet";


// SVG Animation
import TrDig0 from './img/AnimFrames/0.svg'
import TrDig1 from './img/AnimFrames/1.svg'
import TrDig2 from './img/AnimFrames/2.svg'
import TrDig3 from './img/AnimFrames/3.svg'
import TrDig4 from './img/AnimFrames/4.svg'
import TrDig5 from './img/AnimFrames/5.svg'
import TrDig6 from './img/AnimFrames/6.svg'
import TrDig7 from './img/AnimFrames/7.svg'
import TrDig8 from './img/AnimFrames/8.svg'
import TrDig9 from './img/AnimFrames/9.svg'


export default class SoftwareYExtensiones extends PureComponent {

    render() {
        return (
            <>
                <Helmet>
                    <title>BB&IT Enterprise Services / Servicios de IT y Soluciones para Procesos de Negocio</title>
                    <meta name="description" content="Con más de 20 años de experiencia en desarrollo de software personalizado, ofrecemos servicios de desarrollo de software para empresas, grandes y pequeñas." />
                </Helmet>

                <div className="softtwareExtensiones-desk">
                    <div className="container-sections">
                        <div id="container" className="box-servicios">
                            <div className="div-izq-servicios">
                                <div className="txt-servicios">
                                    <h1><TypedReactText
                                        strings={[
                                            'Software y extensiones'
                                        ]}
                                        classNameTyped="title-sections"
                                        backDelay="30000"
                                    /></h1>

                                    <h2><p style={{ color: "#29ABC5" }} className="subtitle-sections">Equipo de Desarrollo</p></h2>
                                    <h3><p style={{ color: "#122E5F", fontSize: "1.45vw" }} className="subtitle-sections">Desarrollo de soluciones de negocio.</p></h3>
                                    <ul style={{ color: "#122E5F" }} className="content-sections">
                                        <li>Acompañamiento de trabajo para la materialización de herramientas digitales.</li>
                                        <li>Extender funcionalidad </li>
                                        <li>Construir funciones ad-hoc</li>
                                        <li>Construir aplicaciones que operen web como móvil.</li>
                                        <li>Integrar aplicaciones y/o plataformas.</li>
                                    </ul>
                                    <img className="img-description" src={img1} />
                                    <ul style={{ color: "#122E5F" }} className="content-sections">
                                        <li>Apps.</li>
                                        <li>Fábrica de Software.</li>
                                        <li>Staffing.</li>
                                        <li>Proyecto.</li>
                                    </ul>

                                    <img className="img-description" src={img2} />
                                </div>

                            </div>

                            <div className="div-der-servicios">
                                <div style={{ width: "37vw", height: "42vw" }}>
                                    <img className="img-descriptionSExt" src={principal} />
                                </div>

                                <div style={{ height: "65vw" }} className="div-contenedor-form">
                                    <FormularioRegistro />
                                </div>
                            </div>

                        </div>

                        <div style={{ width: "100vw", height: "5.5vw", display: "inline-block" }} />
                    </div>

                    <div style={{ width: "100vw", height: "5.5vw", display: "inline-block" }} />
                </div>

                <div className="softtwareExtensiones-mob">
                    <div className="container-sections">
                        <div id="container" className="box-servicios">
                            <div className="div-DetalleServiciosM">
                                <div className="txt-servicios">
                                    <TypedReactText
                                        strings={[
                                            'Software y extensiones'
                                        ]}
                                        classNameTyped="title-sections"
                                        fontSizeCursor="11vw"
                                        backDelay="30000"
                                    />

                                    <p className="subtitle-sectionsM1">Equipo de Desarrollo</p>
                                    <p className="subtitle-sectionsM2">Desarrollo de soluciones de negocio.</p>
                                    <ul className="content-sectionsM">
                                        <li>Acompañamiento de trabajo para la materialización de herramientas digitales.</li>
                                        <li>Extender funcionalidad </li>
                                        <li>Construir funciones ad-hoc</li>
                                        <li>Construir aplicaciones que operen web como móvil.</li>
                                        <li>Integrar aplicaciones y/o plataformas.</li>
                                    </ul>
                                    <img className="img-descriptionM" src={img1} />
                                    <ul className="content-sectionsM">
                                        <li>Apps.</li>
                                        <li>Fábrica de Software.</li>
                                        <li>Staffing.</li>
                                        <li>Proyecto.</li>
                                    </ul>

                                    <img className="img-descriptionM" src={img2} />
                                </div>

                            </div>

                            <div className="div-contenedor-form">
                                <FormularioRegistro />
                            </div>

                        </div>

                    </div>

                </div>

                <div className="div_separatorBootom_formRegister" />

                <ThinkcareSection />

                <NuestroBlog />
            </>
        )
    }
}