import React, { PureComponent } from 'react'
import styles from './BannerActualizado.module.css'
import bannerDesk from './img/bannerThinkcare_desk.png'
import bannerMob from './img/bannerThinkcare_mob.png'

class BannerActualizadoSeccionThinkcare extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {

        };
    }

    render() {
        return (
            <>
                <div className={styles.BannerDesk}>
                    <div className={styles.ContainerBanner}>
                        <img src={bannerDesk} onClick={(e) => { window.open('https://www.thinkcares.com/', ' "_blank"') }} />
                    </div>
                </div>

                <div className={styles.BannerMob}>
                    <div className={styles.ContainerBanner}>
                        <img src={bannerMob} onClick={(e) => { window.open('https://www.thinkcares.com/', ' "_blank"') }} />
                    </div>
                </div>

            </>
        )
    }
}

export default BannerActualizadoSeccionThinkcare;