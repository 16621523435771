import React, { PureComponent } from 'react'
import './index.css'
import NuestroBlog from '../InscribeteBlog/index'
import TypedReactText from '../AnimTyped/index'

export default class Comunidad extends PureComponent {

    render() {
        return (
            <>
                <div className="container-sections">
                    <div className="intro-comunidad">
                        <TypedReactText
                            strings={[
                                'Comunidad'
                            ]}
                            classNameTyped="title-sections"
                            backDelay="30000"
                        />
                    </div>
                </div>

                <NuestroBlog />
            </>
        )
    }
}