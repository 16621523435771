import React, { PureComponent } from 'react'
import './index.css'
import img1 from '../ArqProcServ/img/img1.png'
import img2 from '../ArqProcServ/img/img2.png'
import img3 from '../ArqProcServ/img/img3.png'
import img4 from '../ArqProcServ/img/img4.png'
import img5 from '../ArqProcServ/img/img5.png'
import imgprincipal from '../ArqProcServ/img/principal.svg'
import NuestroBlog from '../InscribeteBlog/index'
import history from '../../history'
import BotonGral from '../BotonGral/index'
import Typing from 'react-typing-animation'
import AnimSVG from '../AnimSVG/index'
import Dropdown from 'react-bootstrap/Dropdown'
import FormularioRegistro from '../FormularioRegistro/index'
import ThinkcareSection from '../ThinkcareSection/bannerActualizado'
import TypedReactText from '../AnimTyped/index'
import {Helmet} from "react-helmet";


// SVG Animation
import TrDig0 from './img/AnimFrames/0.svg'
import TrDig1 from './img/AnimFrames/1.svg'
import TrDig2 from './img/AnimFrames/2.svg'
import TrDig3 from './img/AnimFrames/3.svg'
import TrDig4 from './img/AnimFrames/4.svg'
import TrDig5 from './img/AnimFrames/5.svg'
import TrDig6 from './img/AnimFrames/6.svg'
import TrDig7 from './img/AnimFrames/7.svg'
import TrDig8 from './img/AnimFrames/8.svg'
import TrDig9 from './img/AnimFrames/9.svg'


export default class ArquitecturadeProcesos extends PureComponent {


    render() {
        return (
            <>
                <Helmet>
                    <title>BB&IT Enterprise Services / Servicios de IT y Soluciones para Procesos de Negocio</title>
                    <meta name="description" content="Todo negocio busca la mejora continúa, así como identificar aquellas alternativas que impulsen el desempeño." />
                </Helmet>
                <div className="sectionArquitectura-desk">
                    <div className="container-sections">
                        <div id="container" className="box-servicios">
                            <div className="div-izq-servicios">
                                <div className="txt-servicios">
                                    <h1><TypedReactText
                                        strings={[
                                            'Arquitectura de procesos'
                                        ]}
                                        classNameTyped="title-sections"
                                        backDelay="30000"
                                    /></h1>

                                    <p style={{ fontSize: "2vw", width: "91%" }} className="subtitle-sections">Todo negocio busca la mejora continúa, así como identificar aquellas alternativas que impulsen el desempeño.</p>
                                    <h2><p style={{ color: "#29ABC5", fontSize: "1.8vw" }} className="subtitle-sections">Enterprise Resource Planning / Planificación de recursos empresariales (ERP)</p></h2>
                                    <h3><p style={{ color: "#122E5F", fontSize: "1.45vw" }} className="subtitle-sections">Sistema de Planeación de recursos Empresariales</p></h3>
                                    <p style={{ color: "#122E5F" }} className="content-sections">Administra tu negocio con gestión empresarial que interpreta las operaciones de negocio para encontrar oportunidades de progreso.</p>
                                    <ul style={{ color: "#122E5F" }} className="content-sections">
                                        <li>Realizar planos de negocio.</li>
                                        <li>Realizar análisis de Brechas.</li>
                                        <li>Diseñar procesos a configurar / desarrollar.</li>
                                        <li>Realizar desarrollos e integraciones requeridos.</li>
                                        <li>Capacitar usuarios.</li>
                                    </ul>
                                    <img className="img-description" src={img1} />
                                </div>

                                <div className="txt-servicios">
                                    <h2><p style={{ color: "#29ABC5", fontSize: "1.8vw" }} className="subtitle-sections">Customer Relationship Management / Gestión de Relaciones con Clientes (CRM)</p></h2>
                                    <h3><p style={{ color: "#122E5F", fontSize: "1.45vw" }} className="subtitle-sections">Rastrea las interacciones de todos tus touchpoints digitales desde un solo lugar.</p></h3>
                                    <p style={{ color: "#122E5F" }} className="content-sections">Logra relaciones comerciales para toda la vida. Estructuración y manejo del ecosistema de negocio con un enfoque centrado en la evolución de el usuario.</p>
                                    <ul style={{ color: "#122E5F" }} className="content-sections">
                                        <li>Automatizar los procesos de ventas.</li>
                                        <li>Creación de bases de datos con información de los clientes que pueden ser analizados para fines de marketing y previsión de ventas.</li>
                                        <li>Integración de la planificación, presupuesto y seguimiento de las campañas de marketing.</li>

                                    </ul>
                                    <img className="img-description" src={img2} />
                                </div>

                                <div className="txt-servicios">
                                    <h2><p style={{ color: "#29ABC5", fontSize: "1.8vw" }} className="subtitle-sections">Business Inteligence / Inteligencia de negocio (BI)</p></h2>
                                    <h3><p style={{ color: "#122E5F", fontSize: "1.45vw" }} className="subtitle-sections">Mide  y Evalúa tu Estrategia Digital</p></h3>
                                    <p style={{ color: "#122E5F" }} className="content-sections">Evaluación de métodos de administración y gestión automatizada en una cadena de producción.</p>
                                    <ul style={{ color: "#122E5F" }} className="content-sections">
                                        <li>Dirigir y planear</li>
                                        <li>Grupo análisis.</li>
                                        <li>Identificar la información.</li>
                                        <li>Fuentes de datos.</li>
                                        <li>Procesar información.</li>
                                        <li>Integrar info a DWH</li>
                                        <li>Analizar y Producir información.</li>
                                        <li>Producir salidas.</li>
                                    </ul>
                                    <img className="img-description" src={img3} />
                                </div>

                                <div className="txt-servicios">
                                    <h2><p style={{ color: "#29ABC5", fontSize: "1.8vw" }} className="subtitle-sections">Gobierno de Datos</p></h2>
                                    <h3><p style={{ color: "#122E5F" }} className="content-sections">Genera sistemas de análisis y aplicación de datos para automatizar tu estrategia empresarial.</p></h3>
                                    <ul style={{ color: "#122E5F" }} className="content-sections">
                                        <li>Construir modelo operativo de GD.</li>
                                        <li>Crear política de GD.</li>
                                        <li>Crear organización de GD.</li>
                                        <li>Crear procesos de Operación.</li>
                                        <li>Construir Modelo GD en herramienta.</li>
                                        <li>Crear estándares de datos.</li>
                                    </ul>
                                    <img className="img-description" src={img4} />
                                </div>

                                <div className="txt-servicios">
                                    <h2><p style={{ color: "#29ABC5", fontSize: "1.8vw" }} className="subtitle-sections">CCIN Centro de Competencias de Inteligencia de Negocios.</p></h2>
                                    <h3><p style={{ color: "#122E5F", fontSize: "1.45vw" }} className="subtitle-sections">Soporta la toma de decisiones inteligentemente.</p></h3>
                                    <p style={{ color: "#122E5F" }} className="content-sections">Modelos de datos que permiten alinear a la estrategia tecnológica con la de negocio</p>
                                    <ul style={{ color: "#122E5F" }} className="content-sections">
                                        <li>Alinear estrategia de BI a la Corporativa.</li>
                                        <li>Crear una organización de BI </li>
                                        <li>Realizar Arquitectura.</li>
                                        <li>Mantener Modelo de datos.</li>
                                        <li>Producir salidas.</li>
                                        <li>Gobernar proceso.</li>
                                    </ul>
                                    <img className="img-description" src={img5} />
                                </div>

                            </div>

                            <div className="div-der-servicios">
                                <div style={{ width: "37vw", height: "42vw" }}>
                                    <img className="img-descriptionArq" src={imgprincipal} />
                                </div>

                                <div style={{ height: "234vw" }} className="div-contenedor-form">
                                    <FormularioRegistro />
                                </div>
                            </div>

                        </div>

                        <div style={{ width: "100vw", height: "5.5vw", display: "inline-block" }} />
                    </div>
                </div>

                <div className="sectionArquitectura-mob">
                    <div className="container-sections">
                        <div id="container" className="box-servicios">
                            <div className="div-DetalleServiciosM">
                                <div className="txt-servicios">
                                    <TypedReactText
                                        strings={[
                                            'Arquitectura de procesos'
                                        ]}
                                        classNameTyped="title-sections"
                                        fontSizeCursor="11vw"
                                        backDelay="30000"
                                    />

                                    <p className="subtitle-sectionsM">Todo negocio busca la mejora continúa, así como identificar aquellas alternativas que impulsen el desempeño.</p>
                                    <p className="subtitle-sectionsM1">Enterprise Resource Planning / Planificación de recursos empresariales (ERP)</p>
                                    <p className="subtitle-sectionsM2">Sistema de Planeación de recursos Empresariales</p>
                                    <p className="content-sectionsM">Administra tu negocio con gestión empresarial que interpreta las operaciones de negocio para encontrar oportunidades de progreso.</p>
                                    <ul className="content-sectionsM">
                                        <li>Realizar planos de negocio.</li>
                                        <li>Realizar análisis de Brechas.</li>
                                        <li>Diseñar procesos a configurar / desarrollar.</li>
                                        <li>Realizar desarrollos e integraciones requeridos.</li>
                                        <li>Capacitar usuarios.</li>
                                    </ul>
                                    <img className="img-descriptionM" src={img1} />
                                </div>

                                <div className="txt-servicios">
                                    <p className="subtitle-sectionsM1">Customer Relationship Management / Gestión de Relaciones con Clientes (CRM)</p>
                                    <p className="subtitle-sectionsM2">Rastrea las interacciones de todos tus touchpoints digitales desde un solo lugar.</p>
                                    <p className="content-sectionsM">Logra relaciones comerciales para toda la vida. Estructuración y manejo del ecosistema de negocio con un enfoque centrado en la evolución de el usuario.</p>
                                    <ul className="content-sectionsM">
                                        <li>Automatizar los procesos de ventas.</li>
                                        <li>Creación de bases de datos con información de los clientes que pueden ser analizados para fines de marketing y previsión de ventas.</li>
                                        <li>Integración de la planificación, presupuesto y seguimiento de las campañas de marketing.</li>

                                    </ul>
                                    <img className="img-descriptionM" src={img2} />
                                </div>

                                <div className="txt-servicios">
                                    <p className="subtitle-sectionsM1">Business Inteligence / Inteligencia de negocio (BI)</p>
                                    <p className="subtitle-sectionsM2">Mide  y Evalúa tu Estrategia Digital</p>
                                    <p className="content-sectionsM">Evaluación de métodos de administración y gestión automatizada en una cadena de producción.</p>
                                    <ul className="content-sectionsM">
                                        <li>Dirigir y planear</li>
                                        <li>Grupo análisis.</li>
                                        <li>Identificar la información.</li>
                                        <li>Fuentes de datos.</li>
                                        <li>Procesar información.</li>
                                        <li>Integrar info a DWH</li>
                                        <li>Analizar y Producir información.</li>
                                        <li>Producir salidas.</li>
                                    </ul>
                                    <img className="img-descriptionM" src={img3} />
                                </div>

                                <div className="txt-servicios">
                                    <p className="subtitle-sectionsM1">Gobierno de Datos</p>
                                    <p className="content-sectionsM">Genera sistemas de análisis y aplicación de datos para automatizar tu estrategia empresarial.</p>
                                    <ul className="content-sectionsM">
                                        <li>Construir modelo operativo de GD.</li>
                                        <li>Crear política de GD.</li>
                                        <li>Crear organización de GD.</li>
                                        <li>Crear procesos de Operación.</li>
                                        <li>Construir Modelo GD en herramienta.</li>
                                        <li>Crear estándares de datos.</li>
                                    </ul>
                                    <img className="img-descriptionM" src={img4} />
                                </div>

                                <div className="txt-servicios">
                                    <p className="subtitle-sectionsM1">CCIN Centro de Competencias de Inteligencia de Negocios.</p>
                                    <p className="subtitle-sectionsM2">Soporta la toma de decisiones inteligentemente.</p>
                                    <p className="content-sectionsM">Modelos de datos que permiten alinear a la estrategia tecnológica con la de negocio</p>
                                    <ul className="content-sectionsM">
                                        <li>Alinear estrategia de BI a la Corporativa.</li>
                                        <li>Crear una organización de BI </li>
                                        <li>Realizar Arquitectura.</li>
                                        <li>Mantener Modelo de datos.</li>
                                        <li>Producir salidas.</li>
                                        <li>Gobernar proceso.</li>
                                    </ul>

                                </div>
                            </div>

                            <div className="div-contenedor-form">
                                <FormularioRegistro />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="div_separatorBootom_formRegister" />

                <ThinkcareSection />

                <NuestroBlog />
            </>
        )
    }
}