import React, { PureComponent } from 'react'
import './index.css'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import grupoXXI from './img/grupoXXI.png'
import IEnova from './img/IEnova.svg'
import grupoHerdez from './img/grupoherdez.svg'
import homeDepot from './img/homedepot.svg'
import mondelez from './img/mondelez.svg'
import infor from './img/infor.svg'
import microsoft from './img/microsoft.svg'

export default class NuestrosClientes extends PureComponent {

    render() {
        const params = {
            slidesPerView: "auto",
            spaceBetween: 10,
            autoplay: {
                delay: 60000,
                disableOnInteraction: false
            },
        }

        return (
            <>
                <div className="componentNuestrosClientes-desk">
                    <div className="containerNuestrosClientes">
                        <div style={{ width: "100%" , height:"100%", padding:"2% 10%"}} >
                            <Swiper {...params}>
                                <div>
                                    <div className="div-tituloLogosClientes">Confían en nosotros: </div>
                                    <div className="div-logosClientes">
                                        <img className="img-swiper-nc" src={homeDepot} />
                                        <img className="img-swiper-nc" style={{marginLeft:"10%"}} src={grupoHerdez} />
                                        <img className="img-swiper-nc" style={{marginLeft:"10%"}} src={mondelez} />
                                        <img className="img-swiper-nc" style={{marginLeft:"8%", width:"15vw"}} src={IEnova} />
                                    </div>
                                </div>

                                <div>
                                    <div className="div-tituloLogosPartners">Nuestros Partners: </div>
                                    <div className="div-logosPartners">
                                        <img className="img-swiper-np" style={{marginRight:"10%", width:"10vw"}} src={infor} />
                                        <img className="img-swiper-np" style={{marginRight:"10%"}} src={microsoft} />
                                        <img className="img-swiper-np" src={grupoXXI} />
                                    </div>
                                </div>
                            </Swiper>
                        </div>
                    </div>
                </div>

                <div className="componentNuestrosClientes-mobile">
                    <div className="containerNuestrosClientes">
                        <div style={{ width: "100%" , height:"100%", padding:"6% 6%"}} >
                            <Swiper {...params}>
                                <div>
                                    <div className="div-tituloLogosClientes">Confían en nosotros: </div>
                                    <div className="div-logosClientes">
                                        <img className="img-swiper-nc" src={homeDepot} />
                                        <img className="img-swiper-nc" style={{marginLeft:"10%"}} src={grupoHerdez} />
                                        <img className="img-swiper-nc" style={{marginLeft:"10%"}} src={mondelez} />
                                        <img className="img-swiper-nc" style={{marginLeft:"8%", width:"16vw"}} src={IEnova} />
                                    </div>
                                </div>

                                <div>
                                    <div className="div-tituloLogosPartners">Nuestros Partners: </div>
                                    <div className="div-logosPartners">
                                        <img className="img-swiper-np" style={{marginRight:"8%", width:"10vw"}} src={infor} />
                                        <img className="img-swiper-np" style={{marginRight:"8%", width:"19vw"}} src={microsoft} />
                                        <img className="img-swiper-np" src={grupoXXI} />
                                    </div>
                                </div>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </>
        )

    }

}  