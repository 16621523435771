import React, { PureComponent } from 'react'
import './index.css'
import img1 from '../NubeServicio/img/img1.png'
import img2 from '../NubeServicio/img/img2.png'
import img3 from '../NubeServicio/img/img3.png'
import img4 from '../NubeServicio/img/img4.png'
import img5 from '../NubeServicio/img/img5.png'
import img6 from '../NubeServicio/img/img6.png'
import principal from '../NubeServicio/img/principal.svg'
import NuestroBlog from '../InscribeteBlog/index'
import history from '../../history'
import BotonGral from '../BotonGral/index'
import AnimSVG from '../AnimSVG/index'
import Dropdown from 'react-bootstrap/Dropdown'
import FormularioRegistro from '../FormularioRegistro/index'
import ThinkcareSection from '../ThinkcareSection/bannerActualizado'
import TypedReactText from '../AnimTyped/index'
import {Helmet} from "react-helmet";


// SVG Animation
import TrDig0 from './img/AnimFrames/0.svg'
import TrDig1 from './img/AnimFrames/1.svg'
import TrDig2 from './img/AnimFrames/2.svg'
import TrDig3 from './img/AnimFrames/3.svg'
import TrDig4 from './img/AnimFrames/4.svg'
import TrDig5 from './img/AnimFrames/5.svg'
import TrDig6 from './img/AnimFrames/6.svg'
import TrDig7 from './img/AnimFrames/7.svg'
import TrDig8 from './img/AnimFrames/8.svg'
import TrDig9 from './img/AnimFrames/9.svg'

export default class ServiciosNube extends PureComponent {

    render() {
        return (
            <>
                <Helmet>
                    <title>BB&IT Enterprise Services / Servicios de IT y Soluciones para Procesos de Negocio</title>
                    <meta name="description" content="Con más de 20 años de experiencia en desarrollo de software personalizado, ofrecemos servicios de desarrollo de software para empresas, grandes y pequeñas." />
                </Helmet>
                <div className="serviciosNube-desk">
                    <div className="container-sections">
                        <div id="container" className="box-servicios">
                            <div className="div-izq-servicios">
                                <div className="txt-servicios">
                                    <h1><TypedReactText
                                        strings={[
                                            'Servicios en la nube'
                                        ]}
                                        classNameTyped="title-sections"
                                        backDelay="30000"
                                    /></h1>

                                    <h2><p style={{ color: "#29ABC5", fontSize: "1.8vw" }} className="subtitle-sections">Sistema de Gestión de Transporte / Transportation Management System (TMS)</p></h2>
                                    <h3><p style={{ color: "#122E5F", fontSize: "1.45vw" }} className="subtitle-sections">Aplicación para el manejo y control del transporte que participa en la cadena de producción.</p></h3>
                                    <p style={{ color: "#122E5F" }} className="content-sections">Considera las variables de optimización de recursos para lograr la planeación de transporte más conveniente.</p>

                                    <img className="img-description" src={img1} />
                                </div>

                                <div className="txt-servicios">
                                    <h2><p style={{ color: "#29ABC5", fontSize: "1.8vw" }} className="subtitle-sections">Integración de Aplicaciones Empresariales / Enterprise Application Integration (EAI)</p></h2>
                                    <h3><p style={{ color: "#122E5F", fontSize: "1.45vw" }} className="subtitle-sections">Integración de aplicaciones para empresas.</p></h3>
                                    <p style={{ color: "#122E5F" }} className="content-sections">Soporte y actualización de las aplicaciones empresariales aplicadas a estrategias de venta y negocio.</p>
                                    <ul style={{ color: "#122E5F" }} className="content-sections">
                                        <li>EAI como servicio</li>
                                        <li>Conectamos tu mundo.</li>
                                        <li>Aseguramos la conectividad de tus aplicaciones.</li>
                                        <li>Integramos datos entre tus soluciones de negocio.</li>
                                        <li>Habilitamos negocios basados en datos.</li>
                                    </ul>
                                    <img className="img-description" src={img2} />
                                </div>

                                <div className="txt-servicios">
                                    <h2><p style={{ color: "#29ABC5" }} className="subtitle-sections">Internet de las Cosas / <br></br> Internet of Things (IOT)</p></h2>
                                    <h3><p style={{ color: "#122E5F", fontSize: "1.45vw" }} className="subtitle-sections">Facilitamos la comunicación con tu hardware </p></h3>
                                    <p style={{ color: "#122E5F" }} className="content-sections">Catalizador de la hiperconectividad en el mundo de los negocios para monitorear, analizar y automatizar las tareas de nuestro día a día para mejorar los procesos y crear nuevos modelos comerciales.</p>
                                    <ul style={{ color: "#122E5F" }} className="content-sections">
                                        <li>IoT como servicio.</li>
                                        <li>Integración de PLC.</li>
                                        <li>Integración de Hardware con Software.</li>
                                        <li>Censo de datos a través de dispositivos.</li>
                                        <li>Control de hardware a través de interfaz gráfica (móvil)..</li>
                                    </ul>
                                    <img className="img-description" src={img3} />
                                </div>

                                <div className="txt-servicios">
                                    <h2><p style={{ color: "#29ABC5" }} className="subtitle-sections">Cómputo</p></h2>
                                    <h3><p style={{ color: "#122E5F" }} className="content-sections">Procesamiento adaptado al entorno empresarial del futuro.</p></h3>
                                    <ul style={{ color: "#122E5F" }} className="content-sections">
                                        <li>Plataforma como servicio.</li>
                                        <li>Informática sin servidores.</li>
                                        <li>Pase de CAPEX a OPEX sus tareas de cómputo.</li>
                                        <li>Flexibilice sus métodos de procesamiento.</li>
                                        <li>Crezca de forma flexible y exponencial.</li>
                                        <li>Construya soluciones híbridas.</li>
                                    </ul>
                                    <img className="img-description" src={img4} />
                                </div>

                                <div className="txt-servicios">
                                    <h2><p style={{ color: "#29ABC5", fontSize: "1.8vw" }} className="subtitle-sections">Bases de datos</p></h2>
                                    <h3><p style={{ color: "#122E5F", fontSize: "1.45vw" }} className="subtitle-sections">La oportunidad de encontrar las prácticas óptimas en la información que tus operaciones representan.</p></h3>
                                    <p style={{ color: "#122E5F" }} className="content-sections">Agrupación y visibilidad de todos tus datos de operación. Acceso simultáneo y actualización en tiempo real. Integración con herramientas de gestión automática.</p>
                                    <ul style={{ color: "#122E5F" }} className="content-sections">
                                        <li>Base de datos como servicio.</li>
                                        <li>Responda en milisegundos.</li>
                                        <li>Desempeño totalmente confiable.</li>
                                        <li>Flexibilice sus funciones de base de datos.</li>
                                        <li>Escale en forma simple sus necesidades.</li>
                                        <li>Pague por lo que usa.</li>
                                    </ul>
                                    <img className="img-description" src={img5} />
                                </div>

                                <div className="txt-servicios">
                                    <h2><p style={{ color: "#29ABC5", fontSize: "1.8vw" }} className="subtitle-sections">Plan Informático contra Desastres</p></h2>
                                    <p style={{ color: "#122E5F", fontSize: "1.45vw" }} className="subtitle-sections">Calcula, prueba y ejecuta las estrategias de recuperación de desastres mediante la creación de respaldos, pruebas automáticas, documentación, actualización y reporte. </p>
                                    <p style={{ color: "#122E5F" }} className="content-sections">Garantizar la continuidad de las operaciones en la organización. Minimiza la toma de decisiones durante un desastre o contingencia.</p>
                                    <ul style={{ color: "#122E5F" }} className="content-sections">
                                        <li>Recuperación de desastres como servicio.</li>
                                        <li>Recupere la operatividad en segundos.</li>
                                        <li>Elimine costos de transporte asociados a la recuperación.</li>
                                        <li>Administre elásticamente el almacenaje.</li>
                                        <li>Pague por lo que usa.</li>
                                    </ul>
                                    <img className="img-description" src={img6} />
                                </div>
                            </div>

                            <div className="div-der-servicios">
                                <div style={{ width: "37vw", height: "42vw" }}>
                                    <img className="img-descriptionNube" src={principal} />
                                </div>

                                <div style={{ height: "256vw" }} className="div-contenedor-form">
                                    <FormularioRegistro />
                                </div>
                            </div>

                        </div>
                        <div style={{ width: "100vw", height: "5.5vw", display: "inline-block" }} />
                    </div>
                </div>

                <div className="serviciosNube-mob">
                    <div className="container-sections">
                        <div id="container" className="box-servicios">
                            <div className="div-DetalleServiciosM">
                                <div className="txt-servicios">
                                    <TypedReactText
                                        strings={[
                                            'Servicios en la nube'
                                        ]}
                                        classNameTyped="title-sections"
                                        fontSizeCursor="11vw"
                                        backDelay="30000"
                                    />

                                    <p className="subtitle-sectionsM1">Sistema de Gestión de Transporte / Transportation Management System (TMS)</p>
                                    <p className="subtitle-sectionsM2">Aplicación para el manejo y control del transporte que participa en la cadena de producción.</p>
                                    <p className="content-sectionsM">Considera las variables de optimización de recursos para lograr la planeación de transporte más conveniente.</p>

                                    <img className="img-descriptionM" src={img1} />
                                </div>

                                <div className="txt-servicios">
                                    <p className="subtitle-sectionsM1">Integración de Aplicaciones Empresariales / Enterprise Application Integration (EAI)</p>
                                    <p className="subtitle-sectionsM2">Integración de aplicaciones para empresas.</p>
                                    <p className="content-sectionsM">Soporte y actualización de las aplicaciones empresariales aplicadas a estrategias de venta y negocio.</p>
                                    <ul className="content-sectionsM">
                                        <li>EAI como servicio</li>
                                        <li>Conectamos tu mundo.</li>
                                        <li>Aseguramos la conectividad de tus aplicaciones.</li>
                                        <li>Integramos datos entre tus soluciones de negocio.</li>
                                        <li>Habilitamos negocios basados en datos.</li>
                                    </ul>
                                    <img className="img-descriptionM" src={img2} />
                                </div>

                                <div className="txt-servicios">
                                    <p className="subtitle-sectionsM1">Internet de las Cosas / <br></br> Internet of Things (IOT)</p>
                                    <p className="subtitle-sectionsM2">Facilitamos la comunicación con tu hardware </p>
                                    <p className="content-sectionsM">Catalizador de la hiperconectividad en el mundo de los negocios para monitorear, analizar y automatizar las tareas de nuestro día a día para mejorar los procesos y crear nuevos modelos comerciales.</p>
                                    <ul className="content-sectionsM">
                                        <li>IoT como servicio.</li>
                                        <li>Integración de PLC.</li>
                                        <li>Integración de Hardware con Software.</li>
                                        <li>Censo de datos a través de dispositivos.</li>
                                        <li>Control de hardware a través de interfaz gráfica (móvil)..</li>
                                    </ul>
                                    <img className="img-descriptionM" src={img3} />
                                </div>

                                <div className="txt-servicios">
                                    <p className="subtitle-sectionsM1">Cómputo</p>
                                    <p className="content-sectionsM2">Procesamiento adaptado al entorno empresarial del futuro.</p>
                                    <ul className="content-sectionsM">
                                        <li>Plataforma como servicio.</li>
                                        <li>Informática sin servidores.</li>
                                        <li>Pase de CAPEX a OPEX sus tareas de cómputo.</li>
                                        <li>Flexibilice sus métodos de procesamiento.</li>
                                        <li>Crezca de forma flexible y exponencial.</li>
                                        <li>Construya soluciones híbridas.</li>
                                    </ul>
                                    <img className="img-descriptionM" src={img4} />
                                </div>

                                <div className="txt-servicios">
                                    <p className="subtitle-sectionsM1">Bases de datos</p>
                                    <p className="subtitle-sectionsM2">La oportunidad de encontrar las prácticas óptimas en la información que tus operaciones representan.</p>
                                    <p className="content-sectionsM">Agrupación y visibilidad de todos tus datos de operación. Acceso simultáneo y actualización en tiempo real. Integración con herramientas de gestión automática.</p>
                                    <ul className="content-sectionsM">
                                        <li>Base de datos como servicio.</li>
                                        <li>Responda en milisegundos.</li>
                                        <li>Desempeño totalmente confiable.</li>
                                        <li>Flexibilice sus funciones de base de datos.</li>
                                        <li>Escale en forma simple sus necesidades.</li>
                                        <li>Pague por lo que usa.</li>
                                    </ul>
                                    <img className="img-descriptionM" src={img5} />
                                </div>

                                <div className="txt-servicios">
                                    <p className="subtitle-sectionsM1">Plan Informático contra Desastres</p>
                                    <p className="subtitle-sectionsM2">Calcula, prueba y ejecuta las estrategias de recuperación de desastres mediante la creación de respaldos, pruebas automáticas, documentación, actualización y reporte. </p>
                                    <p className="content-sectionsM">Garantizar la continuidad de las operaciones en la organización. Minimiza la toma de decisiones durante un desastre o contingencia.</p>
                                    <ul className="content-sectionsM">
                                        <li>Recuperación de desastres como servicio.</li>
                                        <li>Recupere la operatividad en segundos.</li>
                                        <li>Elimine costos de transporte asociados a la recuperación.</li>
                                        <li>Administre elásticamente el almacenaje.</li>
                                        <li>Pague por lo que usa.</li>
                                    </ul>
                                    <img className="img-descriptionM" src={img6} />
                                </div>
                            </div>

                            <div className="div-contenedor-form">
                                <FormularioRegistro />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="div_separatorBootom_formRegister" />

                <ThinkcareSection />

                <NuestroBlog />
            </>
        )
    }
}