import React, { PureComponent, Component } from 'react'
import './index.css'
import history from '../../history'
import bbpLogo from './img/bbplogo.svg'
import BotonGral from '../BotonGral/index'
import Estrategia from '../../Images/estrategia.svg'
import ArqProc from '../../Images/arqProc.svg'
import SoftExt from '../../Images/softExt.svg'
import ServNube from '../../Images/servNube.svg'
import iconContactanos from './img/iconcontactanos.svg'
import bbpLogoMobile from './img/bbpLogoMobile.svg'
import flechitamas from '../Footer/img/flechitaFooter.svg'

export default class Header extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            sideDrawerOpen: false,
        }

    }

    drawerToggleClickHandler = () => {
        this.setState({
            sideDrawerOpen: !this.state.sideDrawerOpen
        })
    }

    render() {
        return (
            <>
                <div className="componentHeader-desk">
                    <div className="header">
                        <div className="box-header">
                            <div className="ajustes-thinkcaresLogoAzul">
                                <img className="thinkcaresLogoAzul" src={bbpLogo} onClick={() => { window.scrollTo(0, 0); history.push("/") }} />
                            </div>

                            <div className="menu-header">
                                <BotonGral className="formato-btngral-header" texto="Servicios" color="#122E5F" onClick={() => { window.scrollTo(0, 0); history.push("/servicios") }} />
                                <BotonGral className="formato-btngral-header" texto="Nosotros" color="#122E5F" onClick={() => { window.scrollTo(0, 0); history.push("/nosotros") }} />
                                {/*<BotonGral className="formato-btngral-header" texto="Proyectos" color="#122E5F" onClick={() => { window.scrollTo(0, 0); history.push("/proyectos") }} />
                                <BotonGral className="formato-btngral-header" texto="Comunidad" color="#122E5F" onClick={() => { window.scrollTo(0, 0); history.push("/comunidad") }} />*/}
                                <BotonGral className="formato-btngral-header" color="#122E5F" />
                            </div>
                        </div>
                    </div>

                    <div className="headerSub">
                        <div className="box-headerSub">
                            <div className="div-headerSub" style={{ marginRight: "10%" }} onClick={() => { window.scrollTo(0, 0); history.push("/detalleservicios/Estrategia") }}>
                                <img className="img-headerSub" src={Estrategia} />
                                <p className="p-headerSub">Estrategia</p>
                            </div>

                            <div className="div-headerSub" onClick={() => { window.scrollTo(0, 0); history.push("/detalleservicios/Arquitectura-de-procesos") }}>
                                <img className="img-headerSub" src={ArqProc} />
                                <p className="p-headerSub">Arquitectura de procesos</p>
                            </div>

                            <div className="div-headerSub" onClick={() => { window.scrollTo(0, 0); history.push("/detalleservicios/Software-y-Extensiones") }}>
                                <img className="img-headerSub" src={SoftExt} />
                                <p className="p-headerSub">Software y extensiones</p>
                            </div>

                            <div className="div-headerSub" onClick={() => { window.scrollTo(0, 0); history.push("/detalleservicios/Servicios-Nube") }}>
                                <img className="img-headerSub" src={ServNube} />
                                <p className="p-headerSub">Servicios en la nube</p>
                            </div>


                        </div>
                    </div>
                </div>

                <div className="componentHeader-mobile">
                    <div style={{ height: "100%" }}>
                        <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
                        <SideDrawer show={this.state.sideDrawerOpen} toClose={() => this.drawerToggleClickHandler()} />
                    </div>

                    <div className="headerSub">
                        <div className="box-headerSub">
                            <div className="div-headerSub" style={{ marginRight: "12%" }} onClick={() => { window.scrollTo(0, 0); history.push("/detalleservicios/Estrategia") }}>
                                <img className="img-headerSub" src={Estrategia} />
                                <p className="p-headerSub">Estrategia</p>
                            </div>

                            <div className="div-headerSub" onClick={() => { window.scrollTo(0, 0); history.push("/detalleservicios/Arquitectura-de-procesos") }}>
                                <img className="img-headerSub" src={ArqProc} />
                                <p className="p-headerSub">Arquitectura de procesos</p>
                            </div>

                            <div className="div-headerSub" onClick={() => { window.scrollTo(0, 0); history.push("/detalleservicios/Software-y-Extensiones") }}>
                                <img className="img-headerSub" src={SoftExt} />
                                <p className="p-headerSub">Software y extensiones</p>
                            </div>

                            <div className="div-headerSub" onClick={() => { window.scrollTo(0, 0); history.push("/detalleservicios/Servicios-Nube") }}>
                                <img className="img-headerSub" src={ServNube} />
                                <p className="p-headerSub">Servicios en la nube</p>
                            </div>


                        </div>
                    </div>

                </div>
            </>
        )
    }
}

const Toolbar = props => (
    <header className="toolbar">
        <nav className="toolbar_navigation">
            <div>
                <DrawerToggleButton click={props.drawerClickHandler} />
            </div>
            <div className="toolbar_logo">
                <img className="thinkcaresLogoAzul" src={bbpLogo} onClick={() => { window.scrollTo(0, 0); history.push("/") }} />
            </div>
            <div>
                <img className="icons-header-mob" src={iconContactanos} onClick={() => { window.scrollTo(0, 0); history.push("/contacto") }} />
            </div>
        </nav>
    </header>
);

const DrawerToggleButton = props => (
    <button className="toggle-button" onClick={props.click}>
        <div className="toggle-button_line" />
        <div className="toggle-button_line" />
        <div className="toggle-button_line" />
    </button>
);

class SideDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mostrarServiciosMenuMob: false,
            ocultarMenu: true
        }

    }

    mostrarServiciosMob = () => {
        this.setState({
            mostrarServiciosMenuMob: !this.state.mostrarServiciosMenuMob
        })
    }

    cerrarMenuMob = () => {
        this.setState({
            ocultarMenu: false,
        })
    }

    goToURL = (url) => {
        this.props.toClose();
        window.scrollTo(0, 0);
        history.push(url);
    }

    render() {
        return (
            <>
                <div className="backdrop" onClick={() => { this.props.toClose() }} style={{ display: this.props.show ? null : "none" }} />
                <nav className={`side-drawer ${this.props.show ? 'open' : ''}`}>
                    <ul>
                        <div className="borderBottomLogo div-logoMobile" onClick={() => { this.goToURL("/") }}>
                            <img src={bbpLogoMobile} style={{ width: "18vw" }} />
                        </div>

                        {this.state.mostrarServiciosMenuMob ?
                            <div className="div-logoMobileItems borderBottomMobile" onClick={() => this.mostrarServiciosMob()} style={{ minHeight: "32%", display: "inline-block", padding: "3% 0" }}>
                                <div style={{ padding: "0 0 2%" }}> <a />Servicios <img className="flechita-serviciosmenu-submenu" src={flechitamas} /> </div>
                                <div className="div-logoMobileSubitems div-lisMenuMobile" style={{fontSize:"0.94rem"}}>
                                    <li onClick={() => {this.goToURL("/servicios")}}>•<div/> Todos</li>
                                    <li onClick={() => {this.goToURL("/detalleservicios/Estrategia")}}>•<div/> Estrategia</li>
                                    <li onClick={() => {this.goToURL("/detalleservicios/Arquitectura-de-procesos")}}>•<div/> Arquitectura de procesos</li>
                                    <li onClick={() => {this.goToURL("/detalleservicios/Software-y-Extensiones")}}>•<div/> Software y extensiones</li>
                                    <li onClick={() => {this.goToURL("/detalleservicios/Servicios-Nube")}}>•<div/> Servicios en la nube</li>
                                </div>
                            </div>
                            :
                            <div className="div-logoMobileItems borderBottomMobile" onClick={() => this.mostrarServiciosMob()} ><a /> Servicios <img className="flechita-serviciosmenu" src={flechitamas} /> </div>

                        }

                        {/*<div className="div-logoMobileItems borderBottomMobile" onClick={() => {this.goToURL("/proyectos")}}><a /> Proyectos</div>*/}
                        <div className="div-logoMobileItems borderBottomMobile" onClick={() => {this.goToURL("/nosotros")}}><a /> Nosotros</div>
                        {/*<div className="div-logoMobileItems borderBottomMobile" onClick={() => {this.goToURL("/comunidad")}}><a /> Comunidad</div>*/}
                        <div className="div-logoMobileItems borderBottomMobile" onClick={() => {this.goToURL("/contacto")}}><a /> Contacto</div>

                        {this.state.mostrarServiciosMenuMob ?
                            <div className="div-logoMobileBotonGral" ><BotonGral fontSize="1.4rem" texto="Arma tu solución" className="formato-btngral-azulbrilloso" color="#0086FC" onClick={() => { this.goToURL("/contacto") }} /></div>
                            :
                            <div className="div-logoMobileBotonGralSubmenu"><BotonGral fontSize="1.4rem" texto="Arma tu solución" className="formato-btngral-azulbrilloso" color="#0086FC" onClick={() => { this.goToURL("/contacto") }} /></div>
                        }
                    </ul>
                </nav>
            </>
        )
    }
};
