import React, { PureComponent } from 'react'
import './index.css'
import HistoriasExitoClientes from './HistoriasExitoClientes'

export default class TarjetitasHistoriasExitoClientes extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
        };
    }

    render() {
        return (
            <>
                <div className="container-historiasexitoclientes">
                    {HistoriasExitoClientes.map((component, index) => {
                        if (index <= this.props.valueindex) {
                            return (
                                <div className="div-historiasexitoclientes">
                                    <img className="logo-historiasexitoclientes" src={component.logo} />
                                    <img className="foto-historiasexitoclientes" src={component.foto} />
                                    <div className="datoscliente-historiasexitoclientes">
                                        <div className="nombre-historiasexitoclientes">{component.nombredecliente}</div>
                                        <div className="cargoyempresa-historiasexitoclientes">{component.cargodelcliente}, {component.empresa}</div>
                                    </div>
                                    <div className="comentario-historiasexitoclientes">{component.comentario}</div>
                                </div>
                            )
                        }
                    })}
                </div>

                <div style={{ width: "100vw", display: "inline-block" }} />
            </>
        )
    }
}