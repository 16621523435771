import React, { PureComponent } from 'react'
import './index.css'
import history from '../../history'
import BotonGral from '../BotonGral/index'
import BotonContactanos from '../BotonContactanos/index'

export default class NuestroBlog extends PureComponent {

    render() {


        return (
            <>
                <div className="componentInscribeteBlog-desk">
                    <div className="box-nuestroBlog">
                        {/*<div className="nuestroBlog" />
                        <div className="container-elementos">
                            <div style={{ display: "block" }}>
                                <p className="subtitle-sections2">Suscríbete y forma parte de nuestra comunidad</p>
                                <label className="content-sections">Recibe el contenido más relevante de tecnología</label>
                                <input className="contacto-nuestroBlog" type="email" name="email" autocomplete="off" placeholder="Email de Contacto" />

                            </div>
                            <BotonGral className="formato-btngral-aqua" marginTop="2vw" marginLeft="2.25%" texto="Enviar" color="#122E5F" />
                        </div>

                        <div className="div-botones">
                            <BotonGral marginRight="15%" texto="Arma tu solución" />
                            <BotonGral className="formato-btngral-azulbrilloso" texto="Llámanos al 5579315150" onClick={() => { window.scrollTo(0, 0); history.push("/contacto") }} />
                        </div>
                        </div>*/}
                        <div className="div-botones">
                            <BotonGral marginRight="15%" fontSize="1.83vw" texto="Arma tu solución" />
                            <BotonContactanos />
                        </div>
                    </div>
                </div>

                    <div className="componentInscribeteBlog-mobile">
                        <div className="box-nuestroBlog">
                            {/*<div className="nuestroBlog" />
                            <div className="container-elementos">
                            <div style={{ display: "block" }}>
                                <p className="subtitle-sections2" style={{ width: "70%" }}>Suscríbete y forma parte de nuestra comunidad</p>
                                <label className="content-sections" style={{ fontSize: "3.5vw" }}>Recibe el contenido más relevante de tecnología</label>
                                <input className="contacto-nuestroBlog" type="email" name="email" autocomplete="off" placeholder="Email de Contacto" />

                            </div>
                            <BotonGral className="formato-btngral-aqua" fontSize="3.8vw" marginTop="2vw" marginLeft="6.25%" texto="Enviar" color="#122E5F" />
                            </div>

                            <div className="div-botones">
                                <BotonGral marginRight="15%" fontSize="3.8vw" texto="Arma tu solución" />
                                <BotonGral className="formato-btngral-azulbrilloso" fontSize="3.8vw" texto="Llámanos al 5579315150" onClick={() => { window.scrollTo(0, 0); history.push("/contacto") }} />
                            </div>
                            </div>*/}
                            <div className="div-botones">
                                <BotonGral marginRight="8%" fontSize="0.83rem" texto="Arma tu solución" />
                                <BotonContactanos />
                            </div>
                        </div>
                    </div>
            </>
        )

    }

}