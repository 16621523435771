import React,{PureComponent} from 'react'
import './index.css'
import history from '../../history'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import swiperimages from './swiperimages'
import BotonGral from '../BotonGral/index'
import bbplogo from '../Header/img/bbplogo.svg'

export default class BannerCOVID extends PureComponent{

    render(){
        const paramscovid = {
            slidesPerView: 3,
            spaceBetween: 15,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
              },
        }

        return (
            <div className="container-banner">
                <div className="box-banner">
                    <div className="txt-banner">
                        <p className="txt-banner-b"><a><img src={bbplogo} style={{width:"4vw"}} /></a> ofrece servicios de migración digital que ayudan a facilitar las operaciones de tu empresa durante la contingencia.</p>
                        <p className="txt-banner-a">Conoce nuestro programa de soluciones <BotonGral marginLeft="4%" className="formato-btngral-covid" texto="AQUÍ" color="#03FBFC" fontFamily="Roboto-Bold" fontSize="1.85vw" onClick={() => { window.scrollTo(0, 0); history.push("/covid-19") }}/></p>
                    </div>   
                
                    <div className="espacio-bannerCOVID">
                        <div style={{ width: "100%" }} >
                            <Swiper {...paramscovid}>
                                {swiperimages.map((component, index) => {
                                    return (
                                            <img src={component.imagen} className="img-swiper-covid" />
                                    )
                                }
                                )}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

}