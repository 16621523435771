import React, { PureComponent } from 'react'
import './index.css'
import img1 from '../EstrategiaServicio/img/img1.png'
import img2 from '../EstrategiaServicio/img/img2.png'
import img3 from '../EstrategiaServicio/img/img3.svg'
import img4 from '../EstrategiaServicio/img/img4.svg'
import NuestroBlog from '../InscribeteBlog/index'
import history from '../../history'
import BotonGral from '../BotonGral/index'
import AnimSVG from '../AnimSVG/index'
import Dropdown from 'react-bootstrap/Dropdown'
import ThinkcareSection from '../ThinkcareSection/bannerActualizado'
import FormularioRegistro from '../FormularioRegistro/index'
import TypedReactText from '../AnimTyped/index'
import {Helmet} from "react-helmet";


// SVG Animation
import TrDig0 from './img/AnimFrames/0.svg'
import TrDig1 from './img/AnimFrames/1.svg'
import TrDig2 from './img/AnimFrames/2.svg'
import TrDig3 from './img/AnimFrames/3.svg'
import TrDig4 from './img/AnimFrames/4.svg'
import TrDig5 from './img/AnimFrames/5.svg'
import TrDig6 from './img/AnimFrames/6.svg'
import TrDig7 from './img/AnimFrames/7.svg'
import TrDig8 from './img/AnimFrames/8.svg'
import TrDig9 from './img/AnimFrames/9.svg'

export default class EstrategiaServicios extends PureComponent {

    render() {
        return (
            <>
                <Helmet>
                    <title>BB&IT Enterprise Services / Servicios de IT y Soluciones para Procesos de Negocio</title>
                    <meta name="description" content="Nuestros servicios de estrategia se centran en atender los eventos y oportunidades más críticas de nuestros clientes, ofreciendo alternativas creativas y favorables de solución." />
                </Helmet>
                <div className="sectionEstrategia-desk">
                    <div className="container-sections">
                        <div id="container" className="box-servicios">
                            <div className="div-izq-servicios">
                                <div className="txt-servicios">
                                    <h1><TypedReactText
                                        strings={[
                                            'Estrategia'
                                        ]}
                                        classNameTyped="title-sections"
                                        backDelay="30000"
                                    /></h1>

                                    <p style={{ fontSize: "2vw", width: "91%" }} className="subtitle-sections">Nuestros servicios de estrategia se centran en atender los eventos y oportunidades más críticas de nuestros clientes, ofreciendo alternativas creativas y favorables de solución.</p>
                                    <h2><p style={{ color: "#29ABC5" }} className="subtitle-sections">Estrategia de negocios</p></h2>
                                    <h3><p style={{ color: "#122E5F", fontSize: "1.45vw" }} className="subtitle-sections">Logra un crecimiento sostenible</p></h3>
                                    <p style={{ color: "#122E5F" }} className="content-sections">Planifica con nostros la estrategia que te acerque a donde quieres que tu negocio llegue</p>
                                    <ul style={{ color: "#122E5F" }} className="content-sections">
                                        <li>Planeación Estratégia (BSC)</li>
                                        <li>Mejora de procesos</li>
                                        <li>Check up de negocio(BSC)</li>
                                        <li>Arquitectura de negocios(TOGAF,APQC)</li>
                                    </ul>
                                    <img className="img-description" src={img1} />
                                </div>

                                <div className="txt-servicios">
                                    <h2><p style={{ color: "#29ABC5" }} className="subtitle-sections">Estrategia de Tecnología</p></h2>
                                    <h3><p style={{ color: "#122E5F", fontSize: "1.45vw" }} className="subtitle-sections">Fundamenta el crecimiento</p></h3>
                                    <p style={{ color: "#122E5F" }} className="content-sections">Análisis del panorama tecnológico para diseñar la conversacion de los sistemas</p>
                                    <ul style={{ color: "#122E5F" }} className="content-sections">
                                        <li>Arquitectura de Tecnología (TOGAF).</li>
                                        <li>Arquitectura de Soluciones (COBIT, VAL IT).</li>
                                        <li>Estrategia y Gobierno de Sistemas (COBIT; MAAGTIC).</li>
                                        <li>Entrega de Servicios (ITIL)</li>
                                    </ul>
                                    <img className="img-description" src={img2} />
                                </div>
                            </div>

                            <div className="div-der-servicios">
                                <div style={{ width: "33vw", height: "44vw" }}>
                                    <img style={{ width: "30vw", marginLeft: "5vw" }} className="img-principal" src={img4} />
                                </div>

                                <div className="div-contenedor-form">
                                    <FormularioRegistro />
                                </div>
                            </div>

                        </div>

                        <div style={{ width: "100vw", height: "5.5vw", display: "inline-block" }} />
                    </div>
                </div>

                <div className="sectionEstrategia-mob">
                    <div className="container-sections">
                        <div id="container" className="box-servicios">
                            <div className="div-DetalleServiciosM">
                                <div className="txt-servicios">
                                    <TypedReactText
                                        strings={[
                                            'Estrategia'
                                        ]}
                                        classNameTyped="title-sections"
                                        fontSizeCursor="11vw"
                                        backDelay="30000"
                                    />

                                    <p className="subtitle-sectionsM">Nuestros servicios de estrategia se centran en atender los eventos y oportunidades más críticas de nuestros clientes, ofreciendo alternativas creativas y favorables de solución.</p>
                                    <p className="subtitle-sectionsM1">Estrategia de negocios</p>
                                    <p className="subtitle-sectionsM2">Logra un crecimiento sostenible</p>
                                    <p className="content-sectionsM">Planifica con nostros la estrategia que te acerque a donde quieres que tu negocio llegue</p>
                                    <ul className="content-sectionsM">
                                        <li>Planeación Estratégia (BSC)</li>
                                        <li>Mejora de procesos</li>
                                        <li>Check up de negocio(BSC)</li>
                                        <li>Arquitectura de negocios(TOGAF,APQC)</li>
                                    </ul>
                                    <img className="img-descriptionM" src={img1} />
                                </div>

                                <div className="txt-servicios">
                                    <p className="subtitle-sectionsM1">Estrategia de Tecnología</p>
                                    <p className="subtitle-sectionsM2">Fundamenta el crecimiento</p>
                                    <p className="content-sectionsM">Análisis del panorama tecnológico para diseñar la conversacion de los sistemas</p>
                                    <ul className="content-sectionsM">
                                        <li>Arquitectura de Tecnología (TOGAF).</li>
                                        <li>Arquitectura de Soluciones (COBIT, VAL IT).</li>
                                        <li>Estrategia y Gobierno de Sistemas (COBIT; MAAGTIC).</li>
                                        <li>Entrega de Servicios (ITIL)</li>
                                    </ul>
                                    <img className="img-descriptionM" src={img2} />
                                </div>
                            </div>

                            <div className="div-contenedor-form">
                                <FormularioRegistro />
                            </div>

                        </div>

                    </div>

                </div>

                <div className="div_separatorBootom_formRegister" />

                <ThinkcareSection />

                <NuestroBlog />
            </>
        )
    }
}