import outline0 from './img/Outline-0.svg'
import outline1 from './img/Outline-1.svg'
import outline2 from './img/Outline-2.svg'
import outline3 from './img/Outline-3.svg'
import outline4 from './img/Outline-4.svg'
import outline5 from './img/Outline-5.svg'
import outline6 from './img/Outline-6.svg'
import outline7 from './img/Outline-7.svg'
import outline8 from './img/Outline-8.svg'
import outline9 from './img/Outline-9.svg'
import outline10 from './img/Outline-10.svg'
import outline11 from './img/Outline-11.svg'

export default [
    {
        "id": 1,
        "imagen": outline0
    },
    {
        "id": 2,
        "imagen": outline1
    },
    {
        "id": 3,
        "imagen": outline2
    },
    {
        "id": 4,
        "imagen": outline3
    },
    {
        "id": 5,
        "imagen": outline4
    },
    {
        "id": 6,
        "imagen": outline5
    },
    {
        "id": 7,
        "imagen": outline6
    },
    {
        "id": 8,
        "imagen": outline7
    },
    {
        "id": 9,
        "imagen": outline8
    },
    {
        "id": 10,
        "imagen": outline9
    },
    {
        "id": 11,
        "imagen": outline10
    },
    {
        "id": 12,
        "imagen": outline11
    },
]