import React, { PureComponent } from 'react'
import './index.css'
import Proyectos from './Proyectos'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import ServicdCard from '../ServiceCard'
import signomas from './img/signomas.svg'
import signomenos from './img/signomenos.svg'

export default class TarjetitasProyectos extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
        };
    }

    render() {

        return (
            <>
                <div className="container-tarjetitasproyectos">
                    {Proyectos.map((component, index) => {
                        if (index <= this.props.valueindex) {

                            const JSONdescrip = (component.descripcionproyecto)

                            return (
                                <>
                                    <div className="div-tarjetitasproyectos">
                                        <ServicdCard titulo={component.nombredelproyecto} logoempresa={component.logo} imgsrc={component.foto} imgtrue={signomenos} imgfalse={signomas} descripcion={ReactHtmlParser(JSONdescrip)} />
                                    </div>
                                </>
                            )
                        }
                    })}
                </div>

                <div style={{ width: "100vw", display: "inline-block" }} />
            </>
        )
    }
}