import React, { PureComponent, Component } from 'react'
import './index.css'
import history from '../../history'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import BotonGral from '../BotonGral/index'
import estrategia from './img/estrategia.svg'
import expansiondeprocesos from './img/expansiondeprocesos.svg'
import laboratoriodeprocesos from './img/laboratoriodeprocesos.svg'
import servicioenlanube from './img/servicioenlanube.svg'
import flechita from './img/flechasiguiente.svg'
import nombreserviciosup from './img/nombreserviciosup.svg'
import nombreservicioinf from './img/nombreservicioinf.svg'
import circulo0 from './img/circulo0.png'
import circulo1 from './img/circulo1.svg'
import medcirculo from './img/medcirculo.svg'
import descripservicio from './img/descripservicio.svg'
import descripsernube from './img/descripsernube.svg'
import descripexpdeproc from './img/descripexpdeproc.svg'
import descriplabdeproc from './img/descriplabdeproc.svg'
import Typing from 'react-typing-animation';
import logoAnimServices from './img/logoAnimServices.svg'

export default class TodosLosServicios extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

        }

    }

    render() {

        return (
            <>
                <div className="componentAnimServicios-desk">
                    <div className="container-todoslosservicios">
                        <div className="div-imagenes">
                            <div className="div-logobbp-animServices">
                                <img className="logobbp-animServices" src={logoAnimServices} />
                            </div>

                            <GenerarCirculo
                                animationTimeContenedor="2s"
                                animationTimeFlechita="2s"
                                topVermas="38%"
                                onClickVermas={() => { window.scrollTo(0, 0); history.push("/detalleservicios/Estrategia") }}

                                nombredeservicio="ESTRATEGIA"
                                datatextservicio="ESTRATEGIA"

                                topnombredeservicio="11vw"
                                widthtxtnombredeservicio="58%"
                                descripciondeservicio={ReactHtmlParser("- EMPRESARIAL<br/> - TECNOLÓGICA")}
                                topdescripciondeservicio="40.85vw"

                                imagenfondo={estrategia}
                                widhtImgfondo="5.5vw"
                                flechita={flechita}
                                animTime="17s"
                                animDirection="reverse"
                            />

                            <GenerarCirculo
                                animationTimeContenedor="10s"
                                animationTimeFlechita="2.2s"
                                animationTimeVermas="3s"
                                topVermas="31%"
                                onClickVermas={() => { window.scrollTo(0, 0); history.push("/detalleservicios/Arquitectura-de-procesos") }}

                                nombredeservicio="ARQUITECTURA DE PROCESOS"
                                datatextservicio="ARQUITECTURA DE PROCESOS"

                                topnombredeservicio="36vw"
                                descripciondeservicio={ReactHtmlParser("- AUTOMATIZACIÓN<br/> - GOBIERNO DE DATOS<br/> - INTEGRACIÓN<br/> - ADM. DE MÓDULOS")}
                                topdescripciondeservicio="8vw"
                                widthtxtdescripciondeservicio="50%"

                                imagenfondo={laboratoriodeprocesos}
                                widhtImgfondo="5.5vw"
                                leftImgfondo="53%"
                                flechita={flechita}
                                nombreservicio={nombreservicioinf}
                                topnombreservicio="20vw"
                                descripcionservicio={descriplabdeproc}
                                topdescripservicio="6.25vw"
                                leftdescripservicio="17%"

                            />

                            <GenerarCirculo
                                animationTimeContenedor="18s"
                                animationTimeFlechita="2.4s"
                                topVermas="30%"
                                onClickVermas={() => { window.scrollTo(0, 0); history.push("/detalleservicios/Software-y-Extensiones") }}

                                nombredeservicio="SOFTWARE Y EXTENSIONES"
                                datatextservicio="SOFTWARE Y EXTENSIONES"

                                topnombredeservicio="11vw"
                                widthtxtnombredeservicio="67%"
                                descripciondeservicio={ReactHtmlParser("- DESARROLLO DE APPS<br/>- SOFTWARE CORPORATIVO<br/> - SOLUCIONES ON LINE")}
                                topdescripciondeservicio="39vw"
                                widthtxtdescripciondeservicio="55%"
                                leftdescripciondeservicio="28%"

                                imagenfondo={expansiondeprocesos}
                                flechita={flechita}
                                descripcionservicio={descripexpdeproc}
                                topdescripservicio="31.4vw"
                                leftdescripservicio="24%"
                                animTime="15s"
                                animDirection="reverse"
                            />

                            <GenerarCirculo
                                animationTimeContenedor="26s"
                                animationTimeVermas="3s"
                                onClickVermas={() => { window.scrollTo(0, 0); history.push("/detalleservicios/Servicios-Nube") }}

                                nombredeservicio="SERVICIOS EN LA NUBE"
                                datatextservicio="SERVICIOS EN LA NUBE"

                                topnombredeservicio="36vw"
                                widthtxtnombredeservicio="59%"
                                descripciondeservicio={ReactHtmlParser("- E-COMMERCE<br/> - EIA<br/> - IOT<br/> - COMPUTO<br/> - BASE DE DATOS<br/> - DRP")}
                                topdescripciondeservicio="6.3vw"
                                widthtxtdescripciondeservicio="45%"

                                imagenfondo={servicioenlanube}
                                nombreservicio={nombreservicioinf}
                                topnombreservicio="20vw"
                                descripcionservicio={descripsernube}
                                topdescripservicio="5.67vw"
                                leftdescripservicio="17.2%"
                                animTime="11s"
                            />
                        </div>

                        <div className="div-boton">
                            <BotonGral className="formato-btngral-azulbrilloso" marginLeft="4%" texto="Conoce TODOS los SERVICIOS" color="#ffff" fontSize="1.68vw" onClick={() => { window.scrollTo(0, 0); history.push("/servicios") }} />
                        </div>
                    </div>
                </div>

                <div className="componentAnimServicios-mobile">
                    <div className="container-todoslosservicios">
                        <div className="div-imagenes">
                            <div className="div-logobbp-animServices">
                                <img className="logobbp-animServices" src={logoAnimServices} />
                            </div>

                            <GenerarCirculo
                                animationTimeContenedor="2s"
                                animationTimeFlechita="2s"
                                topVermas="34%"
                                onClickVermas={() => { window.scrollTo(0, 0); history.push("/detalleservicios/Estrategia") }}

                                nombredeservicio="ESTRATEGIA"
                                datatextservicio="ESTRATEGIA"

                                topnombredeservicio="23vw"
                                widthtxtnombredeservicio="58%"
                                descripciondeservicio={ReactHtmlParser("- EMPRESARIAL<br/> - TECNOLÓGICA")}
                                topdescripciondeservicio="100.85vw"

                                imagenfondo={estrategia}
                                widhtImgfondo="17.5vw"
                                animTime="17s"
                                animDirection="reverse"
                            />

                            <GenerarCirculo
                                animationTimeContenedor="6s"
                                animationTimeFlechita="2.2s"
                                animationTimeVermas="3s"
                                topVermas="27%"
                                onClickVermas={() => { window.scrollTo(0, 0); history.push("/detalleservicios/Arquitectura-de-procesos") }}

                                nombredeservicio="ARQUITECTURA DE PROCESOS"
                                datatextservicio="ARQUITECTURA DE PROCESOS"

                                topnombredeservicio="23vw"
                                descripciondeservicio={ReactHtmlParser("- AUTOMATIZACIÓN<br/> - GOBIERNO DE DATOS<br/> - INTEGRACIÓN<br/> - ADM. DE MÓDULOS")}
                                topdescripciondeservicio="98vw"
                                widthtxtdescripciondeservicio="62%"

                                imagenfondo={laboratoriodeprocesos}
                                widhtImgfondo="17.5vw"
                                leftImgfondo="53%"
                                topdescripservicio="82vw"
                                leftdescripservicio="9%"

                            />

                            <GenerarCirculo
                                animationTimeContenedor="10s"
                                animationTimeFlechita="2.4s"
                                topVermas="27%"
                                onClickVermas={() => { window.scrollTo(0, 0); history.push("/detalleservicios/Software-y-Extensiones") }}

                                nombredeservicio="SOFTWARE Y EXTENSIONES"
                                datatextservicio="SOFTWARE Y EXTENSIONES"

                                topnombredeservicio="23vw"
                                widthtxtnombredeservicio="64%"
                                descripciondeservicio={ReactHtmlParser("- DESARROLLO DE APPS<br/>- SOFTWARE CORPORATIVO<br/> - SOLUCIONES ON LINE")}
                                topdescripciondeservicio="101vw"
                                widthtxtdescripciondeservicio="55%"
                                leftdescripciondeservicio="16%"

                                imagenfondo={expansiondeprocesos}
                                topdescripservicio="82.4vw"
                                leftdescripservicio="9%"
                                animTime="15s"
                                animDirection="reverse"
                            />

                            <GenerarCirculo
                                animationTimeContenedor="14s"
                                animationTimeVermas="3s"
                                onClickVermas={() => { window.scrollTo(0, 0); history.push("/detalleservicios/Servicios-Nube") }}

                                nombredeservicio="SERVICIOS EN LA NUBE"
                                datatextservicio="SERVICIOS EN LA NUBE"

                                topnombredeservicio="23vw"
                                widthtxtnombredeservicio="52%"
                                descripciondeservicio={ReactHtmlParser("- E-COMMERCE<br/> - EIA - IOT<br/> - COMPUTO<br/> - BASE DE DATOS<br/> - DRP")}
                                heightImgDescripcionServ="35vw"
                                widhtImgDescripcionServ="42vw"
                                topdescripciondeservicio="99.5vw"
                                leftdescripciondeservicio="12%"
                                widthtxtdescripciondeservicio="45%"

                                imagenfondo={servicioenlanube}
                                topdescripservicio="82.67vw"
                                leftdescripservicio="0%"
                                animTime="11s"
                            />
                        </div>

                        <div className="div-boton">
                            <BotonGral className="formato-btngral-azulbrilloso" marginLeft="4%" texto="Conoce TODOS los SERVICIOS" color="#ffff" fontSize="1.2rem" onClick={() => { window.scrollTo(0, 0); history.push("/servicios") }} />
                        </div>
                    </div>
                </div>
            </>
        )

    }

}

class GenerarCirculo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mostrarDescripcionServicio: false,
            mostrarServicios: true,
        }
    }

    mostrarDescripcionServicio = () => {
        this.setState({
            mostrarDescripcionServicio: !this.state.mostrarDescripcionServicio
        })
    }

    render() {
        return (
            <>
                <div className="componentAnimServicios-desk">
                    {this.state.mostrarServicios ?
                        <div className="contenedorservicios" style={{ position: "relative", width: "24%", height: "100%", float: "left", animationDuration: this.props.animationTimeContenedor ? this.props.animationTimeContenedor : "8s" }}>

                            <img src={medcirculo} id="medcirculo" className="rotatemedcirculo" style={{ position: "absolute", top: "21vw", left: "28.8%", width: "11.4vw", height: "11.4vw", animationDuration: this.props.animTime ? this.props.animTime : "8s", animationDirection: this.props.animDirection ? this.props.animDirection : "normal" }} />
                            <img src={circulo0} id="circulo0" className="" style={{ position: "absolute", top: "19.2vw", left: "21%", width: "15vw", height: "15vw" }} />
                            <img src={circulo1} id="circulo1" className="" style={{ position: "absolute", top: "20vw", left: "24%", width: "13.5vw", height: "13.5vw" }} />
                            <img src={this.props.nombreservicio ? this.props.nombreservicio : nombreserviciosup} id="nombreservicio" className="" style={{ position: "absolute", top: this.props.topnombreservicio ? this.props.topnombreservicio : "7.5vw", left: this.props.leftnombreservicio ? this.props.leftnombreservicio : "-2%", width: "26vw", height: "26vw" }} />
                            <img src={this.props.descripcionservicio ? this.props.descripcionservicio : descripservicio} id="descripcionservicio" className="" style={{ position: "absolute", top: this.props.topdescripservicio ? this.props.topdescripservicio : "32.2vw", left: this.props.leftdescripservicio ? this.props.leftdescripservicio : "17.3%", width: "15vw", height: "15vw", opacity: this.state.mostrarDescripcionServicio ? "1" : "0", transition: "opacity 1s ease" }} />

                            <img className="flechita flechitaAnim"
                                style={{
                                    top: "24vw",
                                    left: this.props.leftflechita ? this.props.leftflechita : "21.5vw",
                                    animationDuration: this.props.animationTimeFlechita ? this.props.animationTimeFlechita : "2s"
                                }}
                                src={this.props.flechita ? this.props.flechita : null}
                            />

                            <div className="circulo" onClick={() => this.mostrarDescripcionServicio()}
                                style={{
                                    top: "22vw",
                                    left: this.props.leftcirculo ? this.props.leftcirculo : "8vw",
                                }}
                            >
                                <img className="img-fondo-circulo" style={{ width: this.props.widhtImgfondo ? this.props.widhtImgfondo : "5vw", left: this.props.leftImgfondo ? this.props.leftImgfondo : "50%" }} src={this.props.imagenfondo} />
                                <div className="vermas flechitaAnim" onClick={this.props.onClickVermas} style={{ top: this.props.topVermas ? this.props.topVermas : "25%", animationDuration: this.props.animationTimeVermas ? this.props.animationTimeVermas : "1.5s", zIndex: "2" }}>VER MÁS</div>
                            </div>

                            <h3><div className="glitch" data-text={this.props.datatextservicio} style={{ float:"left", top: this.props.topnombredeservicio, left: this.props.leftnombredeservicio ? this.props.leftnombredeservicio : "40%", width: this.props.widthtxtnombredeservicio ? this.props.widthtxtnombredeservicio : "64%" }}>
                                {this.props.nombredeservicio}
                            </div></h3>

                            <div className="txt-descripciondeservicio" style={{ top: this.props.topdescripciondeservicio, left: this.props.leftdescripciondeservicio ? this.props.leftdescripciondeservicio : "25%", width: this.props.widthtxtdescripciondeservicio ? this.props.widthtxtdescripciondeservicio : "45%", opacity: this.state.mostrarDescripcionServicio ? "1" : "0", transition: "opacity 2s ease" }}>{this.props.descripciondeservicio}</div>
                        </div>
                        :
                        null
                    }
                </div>

                <div className="componentAnimServicios-mobile">
                    {this.state.mostrarServicios ?
                        <div className="contenedorservicios" style={{ position: "relative", width: "50%", height: "42%", float: "left", animationDuration: this.props.animationTimeContenedor ? this.props.animationTimeContenedor : "4s" }}>

                            <img src={medcirculo} id="medcirculo" className="rotatemedcirculo" style={{ position: "absolute", top: "49vw", left: "14.6%", width: "32.4vw", height: "32.4vw", animationDuration: this.props.animTime ? this.props.animTime : "8s", animationDirection: this.props.animDirection ? this.props.animDirection : "normal" }} />
                            <img src={circulo0} id="circulo0" className="" style={{ position: "absolute", top: "45vw", left: "6%", width: "40.5vw", height: "40.5vw" }} />
                            <img src={circulo1} id="circulo1" className="" style={{ position: "absolute", top: "47vw", left: "10%", width: "36.5vw", height: "36.5vw" }} />
                            <img src={this.props.nombreservicio ? this.props.nombreservicio : nombreserviciosup} id="nombreservicio" className="" style={{ position: "absolute", top: this.props.topnombreservicio ? this.props.topnombreservicio : "11vw", left: this.props.leftnombreservicio ? this.props.leftnombreservicio : "4.5%", width: "42vw", height: "76vw" }} />
                            <img src={this.props.descripcionservicio ? this.props.descripcionservicio : descripservicio} id="descripcionservicio" className="" style={{ position: "absolute", top: this.props.topdescripservicio ? this.props.topdescripservicio : "82.2vw", left: this.props.leftdescripservicio ? this.props.leftdescripservicio : "9.3%", width: this.props.widhtImgDescripcionServ ? this.props.widhtImgDescripcionServ : "36vw", height: this.props.heightImgDescripcionServ ? this.props.heightImgDescripcionServ : "32vw", opacity: this.state.mostrarDescripcionServicio ? "1" : "0", transition: "opacity 1s ease" }} />

                            <div className="circulo" onClick={() => this.mostrarDescripcionServicio()}
                                style={{
                                    top: "52vw",
                                    left: this.props.leftcirculo ? this.props.leftcirculo : "10vw",
                                }}
                            >
                                <img className="img-fondo-circulo" style={{ width: this.props.widhtImgfondo ? this.props.widhtImgfondo : "16vw", left: this.props.leftImgfondo ? this.props.leftImgfondo : "50%" }} src={this.props.imagenfondo} />
                                <div className="vermas flechitaAnim" onClick={this.props.onClickVermas} style={{ top: this.props.topVermas ? this.props.topVermas : "18%", animationDuration: this.props.animationTimeVermas ? this.props.animationTimeVermas : "1.5s", zIndex: "2" }}>VER MÁS</div>
                            </div>

                            <div className="glitch" data-text={this.props.datatextservicio} style={{ float:"left", top: this.props.topnombredeservicio, left: this.props.leftnombredeservicio ? this.props.leftnombredeservicio : "28%", width: this.props.widthtxtnombredeservicio ? this.props.widthtxtnombredeservicio : "61%" }}>
                                {this.props.nombredeservicio}
                            </div>

                            <div className="txt-descripciondeservicio" style={{ top: this.props.topdescripciondeservicio, left: this.props.leftdescripciondeservicio ? this.props.leftdescripciondeservicio : "20%", width: this.props.widthtxtdescripciondeservicio ? this.props.widthtxtdescripciondeservicio : "45%", opacity: this.state.mostrarDescripcionServicio ? "1" : "0", transition: "opacity 2s ease" }}>{this.props.descripciondeservicio}</div>
                        </div>
                        :
                        null
                    }
                </div>
            </>
        );
    }

}