import foto from './img/fotoProyectos.png'
import logomondelez from './img/mondelez.svg'
import logoherdez from './img/grupoherdez.svg'
import logohomedepot from './img/homedepot.svg'

export default [
    {
        "id": 1,
        "foto": foto,
        "logo": logomondelez,
        "nombredelproyecto": "Nombre del proyecto",
        "descripcionproyecto": "• Plataforma de SMS<br/> • Implementación en Azure<br/> • Integración de Whatsapp<br/> • Chatbot inteligente"
    },
    {
        "id": 2,
        "foto": foto,
        "logo": logohomedepot,
        "nombredelproyecto": "Nombre del proyecto",
        "descripcionproyecto": "• Plataforma de SMS<br/> • Implementación en Azure<br/> • Integración de Whatsapp<br/> • Chatbot inteligente"
    },
    {
        "id": 3,
        "foto": foto,
        "logo": logoherdez,
        "nombredelproyecto": "Nombre del proyecto",
        "descripcionproyecto": "• Plataforma de SMS<br/> • Implementación en Azure<br/> • Integración de Whatsapp<br/> • Chatbot inteligente"
    },
    {
        "id": 4,
        "foto": foto,
        "logo": logoherdez,
        "nombredelproyecto": "Nombre del proyecto",
        "descripcionproyecto": "• Plataforma de SMS<br/> • Implementación en Azure<br/> • Integración de Whatsapp<br/> • Chatbot inteligente"
    },
    {
        "id": 5,
        "foto": foto,
        "logo": logomondelez,
        "nombredelproyecto": "Nombre del proyecto",
        "descripcionproyecto": "• Plataforma de SMS<br/> • Implementación en Azure<br/> • Integración de Whatsapp<br/> • Chatbot inteligente"
    },
    {
        "id": 6,
        "foto": foto,
        "logo": logohomedepot,
        "nombredelproyecto": "Nombre del proyecto",
        "descripcionproyecto": "• Plataforma de SMS<br/> • Implementación en Azure<br/> • Integración de Whatsapp<br/> • Chatbot inteligente"
    },
    {
        "id": 7,
        "foto": foto,
        "logo": logohomedepot,
        "nombredelproyecto": "Nombre del proyecto",
        "descripcionproyecto": "• Plataforma de SMS<br/> • Implementación en Azure<br/> • Integración de Whatsapp<br/> • Chatbot inteligente"
    },
    {
        "id": 8,
        "foto": foto,
        "logo": logoherdez,
        "nombredelproyecto": "Nombre del proyecto",
        "descripcionproyecto": "• Plataforma de SMS<br/> • Implementación en Azure<br/> • Integración de Whatsapp<br/> • Chatbot inteligente"
    },
]