import React, { PureComponent, Component } from 'react'
import './index.css'
import history from '../../history'
import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

export default class FormRegistro extends PureComponent{
    constructor(props){
        super(props)
        this.state={       
                datosFormulario:{
                    nombre:"",
                    correo:"",
                    descripcion:"",
                },
                formularioValidado: {
                    nombre: false,
                    correo: false,
                    descripcion: false
                },
                correoInvalido: false,
                nombreInvalido: false,
                descripcionInvalida: false,
            }
        
        this.formRegistroDesk = {
            Nombre: React.createRef(),
            Correo: React.createRef(),
            Descripcion: React.createRef(),
        }

        this.formRegistroMob = {
            Nombre: React.createRef(),
            Correo: React.createRef(),
            Descripcion: React.createRef(),
        }


    }

    handleValidateNombre = value => {
        const valido = value.length > 3;

        return valido;        
    }

    handleValidateCorreo = value => {
        const valido = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value));

        return valido;
    }

    handleValidateDescripcion = value => {
        const valido = value.length > 10;

        return valido;
    }

    handleValidateForm = params => {
        
        const { name, value } = params;    
        let valido = false;    
        
        switch (name) {
            case 'nombre':
                valido = this.handleValidateNombre(value);     
                this.setState({ nombreInvalido: !valido });                           
                break;

            case 'correo':
                valido = this.handleValidateCorreo(value);    
                this.setState({ correoInvalido: !valido });                
                break;

            case 'descripcion':
                valido = this.handleValidateDescripcion(value);
                this.setState({ descripcionInvalida: !valido });             
                break;                   
        }
        
        this.setState(prevState => (                    
                {
                    formularioValidado: {
                        ...prevState.formularioValidado,
                        [name]: valido
                    }
                }
            ),
        );
    }

    handleChangeInputs = event => {
        const { name, value } = event.target;                
        this.setState(prevState => (
            {            
                datosFormulario:{
                    ...prevState.datosFormulario,
                    [name]:value
                }
            }
        ), () => { this.handleValidateForm({name, value}) });        
    }

    resetForm = () => {
        this.formRegistroDesk.Nombre.current.value = ""
        this.formRegistroDesk.Correo.current.value = ""        
        this.formRegistroDesk.Descripcion.current.value = ""
        
        this.formRegistroMob.Nombre.current.value = ""
        this.formRegistroMob.Correo.current.value = ""        
        this.formRegistroMob.Descripcion.current.value = ""
    }

    handleRegistro = async () => {
        const resp = await axios.post("https://app-bbp-sitio-marca-gateway-prod.azurewebsites.net/api/contacto", this.state.datosFormulario)        
        console.log("esto respondio el servicio",resp.data)
        
        Swal.fire({
            title: '¡Muchas gracias!\nTus datos han sido enviados.',
            text: 'Nosotros nos pondremos en contacto.',
            icon: 'success',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#A5DC86',
          })

        this.resetForm();
    }  

    render(){

        const borderRed = {
            borderColor:"red",
        }

        return(
            <>
            <div className="form-desk">
                <div className="separator-div" />
                <div className="form-sticky">
                    <div  className="formContactanos">                                        
                    <div className="formulario">
                        <label className="subtitle-sections">Póngase en contacto con nosotros</label>
                        <label className="content-sections">Te responderemos lo mas pronto posible</label>
                        <form onSubmit={(e) => {e.preventDefault(); this.handleRegistro(e);}}>
                            <input 
                                ref={this.formRegistroDesk.Nombre}
                                onChange={this.handleChangeInputs}
                                style={this.state.nombreInvalido ? borderRed : {}}
                                className="contacto-servicios" 
                                type="text"
                                name="nombre" 
                                autocomplete="off" 
                                placeholder="Nombre Completo"
                                minLength="4"
                                required
                            />
                            <input
                                ref={this.formRegistroDesk.Correo}
                                onChange={this.handleChangeInputs}
                                style={this.state.correoInvalido ? borderRed : {}}
                                className="contacto-servicios"
                                type="email"
                                name="correo"
                                autocomplete="off"
                                placeholder="Email de Contacto"
                                required 
                            />
                            <textarea 
                                ref={this.formRegistroDesk.Descripcion}
                                onChange={this.handleChangeInputs}
                                style={this.state.descripcionInvalida ? borderRed : {}}
                                className="contacto-servicios"
                                name="descripcion"
                                rows="5" cols="40"
                                minLength="10"
                                placeholder="Detalle del Proyecto"
                                required
                            />
                            
                            <button type="submit" className="bttn_formRegistro_css">Enviar</button>
                        </form>
                    </div>                                
                    </div>                                   
                </div>
            </div>

            <div className="form-mob">
                <div className="form-container">
                    <div  className="formContactanosM">                                        
                    <div className="formularioM">
                        <label className="subtitle-sectionsFM">Póngase en contacto con nosotros</label>
                        <label className="content-sectionsFM">Te responderemos lo mas pronto posible</label>

                        <form onSubmit={(e) => {e.preventDefault(); this.handleRegistro(e);}}>
                            <input 
                                ref={this.formRegistroMob.Nombre}
                                onChange={this.handleChangeInputs}
                                style={this.state.nombreInvalido ? borderRed : {}}
                                className="contacto-serviciosFM" 
                                type="text"
                                name="nombre" 
                                autocomplete="off" 
                                placeholder="Nombre Completo"
                                minLength="4"
                                required
                            />
                            <input
                                ref={this.formRegistroMob.Correo}
                                onChange={this.handleChangeInputs}
                                style={this.state.correoInvalido ? borderRed : {}}
                                className="contacto-serviciosFM"
                                type="email"
                                name="correo"
                                autocomplete="off"
                                placeholder="Email de Contacto"
                                required 
                            />
                            <textarea 
                                ref={this.formRegistroMob.Descripcion}
                                onChange={this.handleChangeInputs}
                                style={this.state.descripcionInvalida ? borderRed : {}}
                                className="contacto-serviciosFM"
                                name="descripcion"
                                rows="5" cols="40"
                                minLength="10"
                                placeholder="Detalle del Proyecto"
                                required
                            />
                            
                            <button type="submit" className="bttn_formRegistro_css">Enviar</button>
                        </form>
                    
                    </div>                                
                    </div>                                   
                </div>
            </div>
            
        </>


        )
        
    }

}

