import React, { PureComponent, Component } from 'react'
import './index.css'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import subtituloIntro from './img/subtitulo-AcercaDe.svg'
import TarjetitasProyectos from '../TarjetitasProyectos/index'
import NuestroBlog from '../InscribeteBlog/index'
import fhb from './img/fhb.png'
import fjrm from './img/fjrm.png'
import ojca from './img/ojca.png'
import contornofoto from './img/contornofoto.svg'
import iconCapacidades from './img/capacidades.svg'
import iconAprenderTransformar from './img/aprenderytransformar.svg'
import iconEquipoElite from './img/equipodeelite.svg'
import iconTrayectoria from './img/trayectoria.svg'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import TypedReactText from '../AnimTyped/index'
import {Helmet} from "react-helmet";


export default class AcercaDe extends PureComponent {

    render() {
        const params = {
            slidesPerView: "auto",
            spaceBetween: 10,
            autoplay: {
                delay: 60000,
                disableOnInteraction: false
            },
            pagination: {
                el: '.swiper-pagination',
            },
        }

        return (
            <>
                <Helmet>
                    <title>BB&IT Enterprise Services / Servicios de IT y Soluciones para Procesos de Negocio</title>
                    <meta name="description" content="Con más de 20 años de experiencia en desarrollo de software personalizado, ofrecemos servicios de desarrollo de software para empresas, grandes y pequeñas." />
                </Helmet>
                <div className="sectionNosotros-desk">
                    <div className="container-sections">
                        <div className="intro-acercade">
                            <h2><TypedReactText
                                strings={[
                                    'Nosotros',
                                    'Familia',
                                    'Red',
                                    'Equipo'
                                ]}
                                classNameTyped="title-sections"
                            /></h2>

                            <div className="acercade-imgtitulo">
                                <img style={{ width: "36vw" }} src={subtituloIntro} />
                            </div>
                        </div>

                        <div className="divpantalla-michamicha">
                            <div className="micha-pantalla">
                                <h3><p className="subtitle-sections"> <img src={iconCapacidades} className="icons-subsections" /> Capacidades</p></h3>
                                <p className="content-sections">Para proveer mucho más que una solución de Software, en BBP, <a style={{ fontFamily: "Roboto-Bold" }}>conocemos los lenguajes de programación</a> necesarios para conectar a tu empresa con el mundo.<br /> Nos apasiona <a style={{ fontFamily: "Roboto-Bold" }}>descubrir nuevas formas</a> de aprovechar el <a style={{ fontFamily: "Roboto-Bold" }}>potencial tecnológico</a> para gestionar <a style={{ fontFamily: "Roboto-Bold" }}>cualquier negocio</a>.</p>
                            </div>
                            <div className="micha-pantalla">
                                <h3><p className="subtitle-sections"> <img src={iconAprenderTransformar} className="icons-subsections" /> Aprender y transformar</p></h3>
                                <p className="content-sections">La aceleración con la que aparecen nuevas alternativas de <a style={{ fontFamily: "Roboto-Bold" }}>gestión a través de una pantalla</a>, nos ha inspirado a enfocar el valor de nuestras soluciones en el <a style={{ fontFamily: "Roboto-Bold" }}>acompañamiento con el cliente</a>.<br /> Ante los límites de una idea, <a style={{ fontFamily: "Roboto-Bold" }}>en el ADN de nuestro equipo impera la capacidad de saber “cómo sí”</a>.</p>
                            </div>
                        </div>

                        <div className="container-equipodelideres">
                            <EquipoDeLiderazgo
                                fotolideres={fhb}
                                nombrelider="Fernando Herrera Beltrán"
                                cargolider={ReactHtmlParser("Director General<br/> Director de Tecnología")}
                            />

                            <EquipoDeLiderazgo
                                fotolideres={ojca}
                                nombrelider="Omar Javier Contreras Arellano"
                                cargolider="Director de Desarrollo"
                            />

                            <EquipoDeLiderazgo
                                fotolideres={fjrm}
                                nombrelider="Francisco Javier Romero Moran"
                                cargolider="Director de Consultoría y ventas"
                            />
                        </div>
                        <div className="div-fraselideres">
                            <p className="subtitle-sections" style={{ fontSize: "2vw", width: "100%" }}>En BBP, nuestro propósito es:</p>
                            
                            <TypedReactText
                                strings={[
                                    '“Hacer que los sistemas dialoguen”',
                                ]}
                                classNameTyped="frase-equipoliderazgo"
                                fontSizeCursor="4vw"
                                colorCursor="#25C8F6"
                                backDelay="6000"
                                cursorChar="/"
                            />

                            <p className="nombrelider" style={{ marginTop: "1vw" }}>Fernando Herrera Beltrán</p>
                        </div>

                        <div style={{ width: "100vw", height: "2vw", display: "inline-block" }} />

                        <div className="divpantalla-michamicha">
                            <div className="micha-pantalla">
                                <h3><p className="subtitle-sections"> <img src={iconEquipoElite} className="icons-subsections" /> Equipo de élite</p></h3>
                                <p className="content-sections"><a style={{ fontFamily: "Roboto-Bold" }}>El motor de nuestro trabajo está escrito en código.</a> Cuando la estrategia ya se ha planteado y los alcances han sido trazados, <a style={{ fontFamily: "Roboto-Bold" }}>nuestro equipo de desarrollo en Los Mochis, Sinaloa </a>, se encarga de materializar las plataformas digitales para gestionar tus <a style={{ fontFamily: "Roboto-Bold" }}>procesos desde cualquier pantalla</a>.</p>
                            </div>
                            <div className="micha-pantalla">
                                <h3><p className="subtitle-sections"> <img src={iconTrayectoria} className="icons-subsections" /> Trayectoria</p></h3>
                                <p className="content-sections">Consolidarnos como una <a style={{ fontFamily: "Roboto-Bold" }}>organización de expertos en gestión de negocio</a> a través de la tecnología nos ha hecho enfrentar con éxito los retos que la <a style={{ fontFamily: "Roboto-Bold" }}>evolución informática</a> ha presentado en las dos últimas décadas.<br /> Desde el AS400 hasta los Servicios en la Nube, <a style={{ fontFamily: "Roboto-Bold" }}>BBP ha sido el vehículo que conduce a las empresas del futuro</a>.</p>
                            </div>
                        </div>

                        {/*<div className="historiasdeexito-acercade">
                            <p className="subtitle-sections">Proyectos</p>
                            <p className="content-sections">El objetivo principal de la agencia es crear productos digitales únicos que reflejen los valores y la identidad de las marcas. Hemos implementado herramientas que suponen el motor del desarrollo tecnológico de grandes empresas en todo el mundo.</p>
                        </div>
                        <TarjetitasProyectos valueindex="2" />

                        <div className="nuestrafilosofia-acercade">
                            <p className="subtitle-sections">Nuestra filosofía</p>
                            <div className="content-sections">
                                • Transparencia completa a través del compromiso del proyecto.<br />
                                • Comunicación continua para garantizar un manejo perfecto de sus expectativas.<br />
                                • Garantía de calidad de clase empresarial y pruebas de aplicaciones.<br />
                                • Compromiso con el aprendizaje continuo y la innovación.<br />
                                • Completa responsabilidad y confiabilidad
                            </div>
                        </div>*/}

                    </div>

                    <div style={{ width: "100vw", height: "4vw", display: "inline-block" }} />
                    <NuestroBlog />
                </div>

                <div className="sectionNosotros-mobile">
                    <div className="container-sections">
                        <div className="intro-acercade">
                            <h2><TypedReactText
                                strings={[
                                    'Nosotros',
                                    'Familia',
                                    'Red',
                                    'Equipo'
                                ]}
                                classNameTyped="title-sections"
                                fontSizeCursor="11vw"
                            /></h2>
                            <div className="acercade-imgtitulo">
                                <img style={{ width: "13rem" }} src={subtituloIntro} />
                            </div>
                        </div>


                        <div className="micha-pantalla">
                            <h3><p className="subtitle-sections"> <img src={iconCapacidades} className="icons-subsections" /> Capacidades</p></h3>
                            <p className="content-sections">Para proveer mucho más que una solución de Software, en BBP, <a style={{ fontFamily: "Roboto-Bold" }}>conocemos los lenguajes de programación</a> necesarios para conectar a tu empresa con el mundo.<br /> Nos apasiona <a style={{ fontFamily: "Roboto-Bold" }}>descubrir nuevas formas</a> de aprovechar el <a style={{ fontFamily: "Roboto-Bold" }}>potencial tecnológico</a> para gestionar <a style={{ fontFamily: "Roboto-Bold" }}>cualquier negocio</a>.</p>
                        </div>

                        <div className="micha-pantalla">
                            <h3><p className="subtitle-sections"> <img src={iconAprenderTransformar} className="icons-subsections" /> Aprender y transformar</p></h3>
                            <p className="content-sections">La aceleración con la que aparecen nuevas alternativas de <a style={{ fontFamily: "Roboto-Bold" }}>gestión a través de una pantalla</a>, nos ha inspirado a enfocar el valor de nuestras soluciones en el <a style={{ fontFamily: "Roboto-Bold" }}>acompañamiento con el cliente</a>.<br /> Ante los límites de una idea, <a style={{ fontFamily: "Roboto-Bold" }}>en el ADN de nuestro equipo impera la capacidad de saber “cómo sí”</a>.</p>
                        </div>

                        <div className="container-equipodelideres">
                            <Swiper {...params}>
                                <div style={{position:"relative"}}>
                                    <EquipoDeLiderazgo
                                        fotolideres={fhb}
                                        nombrelider="Fernando Herrera Beltrán"
                                        cargolider={ReactHtmlParser("Director General<br/> Director de Tecnología")}
                                    />

                                    <div className="div-fraselideres">
                                        <p className="subtitle-sections" style={{ fontSize: "0.69rem", width: "100%" }}>En BBP, nuestro propósito es:</p>
                                        <TypedReactText
                                            strings={[
                                                '“Hacer que los sistemas dialoguen”',
                                            ]}
                                            classNameTyped="frase-equipoliderazgo"
                                            fontSizeCursor="5.5vw"
                                            colorCursor="#25C8F6"
                                            backDelay="6000"
                                            cursorChar="/"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <EquipoDeLiderazgo
                                        fotolideres={ojca}
                                        nombrelider="Omar Javier Contreras Arellano"
                                        cargolider="Director de Desarrollo"
                                    />

                                    <div className="div-fraselideres">
                                        <p className="subtitle-sections" style={{ fontSize: "0.69rem", width: "100%" }}>En BBP, nuestro propósito es:</p>
                                        <TypedReactText
                                            strings={[
                                                '“Hacer que los sistemas dialoguen”',
                                            ]}
                                            classNameTyped="frase-equipoliderazgo"
                                            fontSizeCursor="5.5vw"
                                            colorCursor="#25C8F6"
                                            backDelay="6000"
                                            cursorChar="/"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <EquipoDeLiderazgo
                                        fotolideres={fjrm}
                                        nombrelider="Francisco Javier Romero Moran"
                                        cargolider="Director de Consultoría y ventas"
                                    />

                                    <div className="div-fraselideres">
                                        <p className="subtitle-sections" style={{ fontSize: "0.69rem", width: "100%" }}>En BBP, nuestro propósito es:</p>
                                        <TypedReactText
                                            strings={[
                                                '“Hacer que los sistemas dialoguen”',
                                            ]}
                                            classNameTyped="frase-equipoliderazgo"
                                            fontSizeCursor="5.5vw"
                                            colorCursor="#25C8F6"
                                            backDelay="6000"
                                            cursorChar="/"
                                        />
                                    </div>
                                </div>
                            </Swiper>
                        </div>

                        <div style={{ width: "100%", height: "2vw", display: "inline-block" }} />

                        
                        <div className="micha-pantalla">
                            <h3><p className="subtitle-sections"> <img src={iconEquipoElite} className="icons-subsections" /> Equipo de élite</p></h3>
                            <p className="content-sections"><a style={{ fontFamily: "Roboto-Bold" }}>El motor de nuestro trabajo está escrito en código.</a> Cuando la estrategia ya se ha planteado y los alcances han sido trazados, <a style={{ fontFamily: "Roboto-Bold" }}>nuestro equipo de desarrollo en Los Mochis, Sinaloa </a>, se encarga de materializar las plataformas digitales para gestionar tus <a style={{ fontFamily: "Roboto-Bold" }}>procesos desde cualquier pantalla</a>.</p>
                        </div>

                        <div className="micha-pantalla">
                            <h3><p className="subtitle-sections"> <img src={iconTrayectoria} className="icons-subsections" /> Trayectoria</p></h3>
                            <p className="content-sections">Consolidarnos como una <a style={{ fontFamily: "Roboto-Bold" }}>organización de expertos en gestión de negocio</a> a través de la tecnología nos ha hecho enfrentar con éxito los retos que la <a style={{ fontFamily: "Roboto-Bold" }}>evolución informática</a> ha presentado en las dos últimas décadas.<br /> Desde el AS400 hasta los Servicios en la Nube, <a style={{ fontFamily: "Roboto-Bold" }}>BBP ha sido el vehículo que conduce a las empresas del futuro</a>.</p>
                        </div>

                        {/*<div className="historiasdeexito-acercade">
                            <p className="subtitle-sections">Proyectos</p>
                            <p className="content-sections">El objetivo principal de la agencia es crear productos digitales únicos que reflejen los valores y la identidad de las marcas. Hemos implementado herramientas que suponen el motor del desarrollo tecnológico de grandes empresas en todo el mundo.</p>
                        </div>
                        <TarjetitasProyectos valueindex="2" />

                        <div className="nuestrafilosofia-acercade">
                            <p className="subtitle-sections">Nuestra filosofía</p>
                            <div className="content-sections">
                                • Transparencia completa a través del compromiso del proyecto.<br />
                                • Comunicación continua para garantizar un manejo perfecto de sus expectativas.<br />
                                • Garantía de calidad de clase empresarial y pruebas de aplicaciones.<br />
                                • Compromiso con el aprendizaje continuo y la innovación.<br />
                                • Completa responsabilidad y confiabilidad
                            </div>
                        </div>*/}

                    </div>

                    <div style={{ width: "100vw", height: "4vw", display: "inline-block" }} />
                    <NuestroBlog />
                </div>
            </>
        )
    }
}

class EquipoDeLiderazgo extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        return (
            <div className="container-equipoliderazgo-class">
                <img src={this.props.fotolideres} className="foto-lideres" />
                <img src={contornofoto} className="contorno-foto" />
                <p className="nombrelider">{this.props.nombrelider}</p>
                <p className="cargolider">{this.props.cargolider}</p>
            </div>
        );
    }

}