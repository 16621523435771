import React, { PureComponent } from 'react'
import './index.css'
import history from '../../history'
import iconWhatsapp from './img/whatsappBussiness.svg'

export default class BotonContactanos extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
        };
    }

    render() {

        return (
            <div style={{position:"relative"}}>
                <div className={this.props.className ? this.props.className : "formato-btngral-contactanos"}>
                    <p onClick={this.props.onClick ? this.props.onClick : () => { window.scrollTo(0, 0); history.push("/contacto") }}>
                        Escríbenos al <a className="num-tel-btncontactanos">55.7931.5150</a>
                    </p>
                    <div className="div-icon-what">
                        <img className="icon-what-contactanos" src={iconWhatsapp} />
                    </div>
                </div>
            </div>
        )

    }

}