import React, { Component } from 'react'
import './index.css'

export default class  AnimSVG extends Component {
    constructor(props){
        super(props)
        this.state = {
            statusAnim: [],
        }
        this.actualFrame = 0;
    }
    
    componentWillMount(){
        let lengthChild = React.Children.count(this.props.children)
        let copyStatusAnim = this.state.statusAnim
        for (let i = 0 ; i < lengthChild; i++){
            copyStatusAnim[i] = false
        }

        copyStatusAnim[0] = true;

        this.setState({
            statusAnim: copyStatusAnim,
        })
    }

    changeFrame = () => {
        let copyStatusAnim = this.state.statusAnim
        let lengthChild = React.Children.count(this.props.children)
        this.actualFrame = this.actualFrame + 1
        
        if(this.actualFrame === lengthChild){
            this.actualFrame = 0;
            for (let i = 0 ; i < lengthChild; i++){
                copyStatusAnim[i] = false
            }
            copyStatusAnim[0] = true;
            
        }
        else {
            copyStatusAnim[this.actualFrame] = true;
        }

        this.setState({
            statusAnim: copyStatusAnim
        })
    }
    
    componentDidMount(){
        this.timerID = setInterval(
            () => this.changeFrame(),
            this.props.duration
          );
    }

    componentWillUnmount(){
        clearInterval(this.timerID)
    }
    

    render(){
        return(
            <div className="animsvg-root" style={{height: this.props.height, width: this.props.width}}>
                {React.Children.map(this.props.children, (child, i) => {
                    return(
                        <div className="animsvg-frame" style={{opacity: this.state.statusAnim[i] ? "1" : "0" }}>
                        {child}
                        </div>
                    )
                })}
            </div>

        )
    }

}