import React, { PureComponent, Component } from 'react'
import './index.css'
import history from '../../history'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import servNube from './img/servicioNube.png'
import estrategia from './img/estrategia.png'
import arqProcesos from './img/arquitecturaProcesos.png'
import softExtensiones from './img/softwareExtensiones.png'
import iconEstrategia from '../../Images/estrategia.svg'
import iconArqProc from '../../Images/arqProc.svg'
import iconSoftExt from '../../Images/softExt.svg'
import iconServNube from '../../Images/servNube.svg'
import BotonGral from '../BotonGral/index'
import iconmas from './img/sabermas.svg'

export default class PotenciamosIdeas extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {

        return (

            <div>
                <h2 className="subtitle-sections" style={{ marginBottom: "1.5vw", color: "#0078C1" }}>Potenciamos ideas</h2>
                <p className="content-sections" style={{ width: "88%" }}>
                    Con más de 20 años de experiencia en desarrollo de software personalizado, ofrecemos servicios de desarrollo de software para empresas, grandes y pequeñas.
                        </p>

                <TarjetitasPotenciamosIdeas imgsrc={estrategia} logo={iconEstrategia} nombredelproyecto="Estrategia" descripcion="Explora tus alternativas de crecimiento / Diagnóstico empresarial para la toma de decisiones.<br/>• Estrategia Empresarial<br/>• Estrategia Tecnológica" URL="/Estrategia" />
                <TarjetitasPotenciamosIdeas imgsrc={arqProcesos} classNameFoto="back-foto-arquitectura" logo={iconArqProc} nombredelproyecto="Arquitectura de Procesos" descripcion="Diseño e integración de herramientas basado en el análisis puntual de oportunidades / Soluciones ERP centradas en el cliente.<br/>• Automatización<br/>• Gobierno de Datos<br/>• Integraciones de Aplicaciones Empresariales<br/>• ERP" URL="/Arquitectura-de-procesos" />
                <TarjetitasPotenciamosIdeas imgsrc={softExtensiones} classNameFoto="back-foto-software" logo={iconSoftExt} nombredelproyecto="Software y extensiones" descripcion="Extensiones digitales centradas en la experiencia eficaz del usuario.<br/>• Desarrollo de Apps<br/>• Software Corporativo<br/>• Soluciones online" URL="/Software-y-Extensiones" />
                <TarjetitasPotenciamosIdeas imgsrc={servNube} classNameFoto="back-foto-nube" logo={iconServNube} nombredelproyecto="Servicios en la nube" descripcion="Control y automatización de procesos desde la red.<br/>• Implementación de E-Commerce<br/>• Enterprise Application Integration (EIA)<br/>• Internet of things (IoT)<br/>• Cómputo<br/>• Base de Datos<br/>• DRP" URL="/Servicios-Nube" />
            </div>

        )

    }

}

class TarjetitasPotenciamosIdeas extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mostrarDetalleServicioMobile: false,
        }
    }

    mostrarDetalleServicioMob = () => {
        this.setState({
            mostrarDetalleServicioMobile: !this.state.mostrarDetalleServicioMobile
        })
    }

    render() {
        return (
            <>
                <div className="componentPotenciamosIdeas-desk">
                    <div className="line-tarjetitas">
                        <div className={this.props.classNameFoto ? this.props.classNameFoto : "back-foto-estrategia"}>
                            <img className="foto-tarjetitaspotideas" src={this.props.imgsrc} />
                        </div>

                        <div className="containertxt-tarjetitaspotideas">
                            <h2><div className="div-logo-nombre-potideas">
                                <img className="logo-tarjetitaspotideas" src={this.props.logo} />
                                <div className="nombre-tarjetitaspotideas">{this.props.nombredelproyecto}</div>
                            </div></h2>
                            <div className="descripcion-tarjetitaspotideas">{ReactHtmlParser(this.props.descripcion)}</div>
                            <div className="div-sabermas-potideas">
                                <img src={iconmas} style={{ width: "3vw", cursor: "pointer" }} onClick={() => { window.scrollTo(0, 0); history.push("/detalleservicios" + (this.props.URL)) }} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="componentPotenciamosIdeas-mobile">
                    <div className="line-tarjetitas" onClick={() => this.mostrarDetalleServicioMob()} >
                        <div className={this.props.classNameFoto ? this.props.classNameFoto : "back-foto-estrategia"}>
                            <img className="foto-tarjetitaspotideas" src={this.props.imgsrc} />
                        </div>

                        <div className="containertxt-tarjetitaspotideas">
                            <img className="logo-tarjetitaspotideas" src={this.props.logo} />
                            <div className="nombre-tarjetitaspotideas">{this.props.nombredelproyecto}</div>
                        </div>

                        { this.state.mostrarDetalleServicioMobile ?
                            <div className="container-descripcionServicio-mobile">
                                <div className="descripcion-tarjetitaspotideas">{ReactHtmlParser(this.props.descripcion)}</div>
                                <div className="div-sabermas-potideas">
                                    <img src={iconmas} style={{ width: "6vw", cursor: "pointer" }} onClick={() => { window.scrollTo(0, 0); history.push("/detalleservicios" + (this.props.URL)) }} />
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
            </>
        );
    }
}