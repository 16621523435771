import React, { PureComponent } from 'react'
import './index.css'
import history from '../../history'

export default class BotonGral extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
        };
    }

    render() {

        return (
            <div className={this.props.className ? this.props.className : "formato-btngral-azul"}
                style={{
                    color: this.props.color ? this.props.color : "#0078C1",
                    marginLeft: this.props.marginLeft ? this.props.marginLeft : "0",
                    marginRight: this.props.marginRight ? this.props.marginRight : "0",
                    marginTop: this.props.marginTop? this.props.marginTop : "0",
                    marginBottom: this.props.marginBottom ? this.props.marginBottom : "0",
                    fontFamily: this.props.fontFamily ? this.props.fontFamily : "Roboto-Medium",
                    fontSize: this.props.fontSize ? this.props.fontSize : "1.5vw"
                }}
            >
                <p onClick={this.props.onClick ? this.props.onClick : () => { window.scrollTo(0, 0); history.push("/contacto") }}>
                    {this.props.texto ? this.props.texto : "Contacto"}
                </p>
            </div>
        )

    }

}