import foto from './img/iconFoto.svg'
import herdez from './img/iconHerdez.svg'
import homedepot from './img/iconHomeDepot.svg'
import mondelez from './img/iconMondelez.svg'

export default [
    {
        "id": 1,
        "foto": foto,
        "logo": herdez,
        "nombredecliente": "Nombre de cliente",
        "cargodelcliente": "Cargo del cliente",
        "empresa": "empresa",
        "comentario": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        "id": 2,
        "foto": foto,
        "logo": homedepot,
        "nombredecliente": "Nombre de cliente",
        "cargodelcliente": "Cargo del cliente",
        "empresa": "empresa",
        "comentario": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        "id": 3,
        "foto": foto,
        "logo": mondelez,
        "nombredecliente": "Nombre de cliente",
        "cargodelcliente": "Cargo del cliente",
        "empresa": "empresa",
        "comentario": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        "id": 4,
        "foto": foto,
        "logo": herdez,
        "nombredecliente": "Nombre de cliente",
        "cargodelcliente": "Cargo del cliente",
        "empresa": "empresa",
        "comentario": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        "id": 5,
        "foto": foto,
        "logo": homedepot,
        "nombredecliente": "Nombre de cliente",
        "cargodelcliente": "Cargo del cliente",
        "empresa": "empresa",
        "comentario": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
]