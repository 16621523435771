import React, { PureComponent } from 'react'
import './index.css'
import TarjetitasProyectos from '../TarjetitasProyectos/index'
import TypedReactText from '../AnimTyped/index'
import NuestroBlog from '../InscribeteBlog/index'
import infor from '../NuestrosClientes/img/infor.svg'
import microsoft from '../NuestrosClientes/img/microsoft.svg'
import GrupoXXI from '../NuestrosClientes/img/grupoXXI.png'

export default class Proyectos extends PureComponent {

    render() {

        return (
            <>
                <div className="container-sections">
                    <div className="intro-proyectos">
                        <TypedReactText
                            strings={[
                                'Proyectos',
                                'Imagina',
                                'Crea',
                                'Plásmalo',
                                '¡Contáctanos!'
                            ]}
                            classNameTyped="title-sections"
                        />

                        <p className="content-sections" style={{ marginBottom: "5vw" }}>El objetivo principal de la agencia es crear productos digitales únicos que reflejen los valores y la identidad de las marcas. Hemos implementado herramientas que suponen el motor del desarrollo tecnológico de grandes empresas en todo el mundo.</p>
                    </div>
                    <TarjetitasProyectos valueindex="8" />
                </div>

                <div className="proyectosBanner-nuestrospartners">
                    <p className="subtitle-sections">Nuestros partners</p>
                    <p className="content-sections">Colaboramos con marcas reconocidas mundialmente para entregar herramientas que sean más seguras / accesibles / avanzadas / completas.</p>
                    <div className="div-imagenes-proyectosBanner">
                        <img src={infor} style={{ width: "10vw" }} />
                        <img src={microsoft} />
                        <img src={GrupoXXI} />
                    </div>
                </div>

                <div style={{ width: "100%", height: "5.5vw" }} />

                <NuestroBlog />
            </>
        )

    }

} 
