import React, { PureComponent, Component } from 'react'
import './index.css'

class ServiceCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }

    }

    changeShow = () => {
        this.setState({
            open: !this.state.open
        })
    }

    render() {
        return (
            <div className="root-service-card" style={this.props.style} >
                <div className="container-img-service">
                    <img className="img-service-card" src={this.props.imgsrc} />
                </div>
                <div className="content-service-card" >
                    <div style={{display:"flex", justifyContent:"space-between", marginBottom:"1vw"}}>
                        <img className="logo-empresa" src={this.props.logoempresa} />
                        <div className="signo-service-card cursor" onClick={() => this.changeShow()}>
                            <img style={{ width: "3vw" }} src={this.state.open ? this.props.imgtrue : this.props.imgfalse} />
                        </div>
                    </div>
                    <div className="title-service-card cursor" onClick={() => this.changeShow()} >{this.props.titulo}</div>
                    <div className="description-service-card" style={{ height: this.state.open ? "10vw" : "0" }} >
                        {this.props.descripcion}
                    </div>
                </div>

            </div>
        )
    }
}

export default ServiceCard;