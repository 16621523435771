import React, { Component } from 'react'
import './App.css'
import { Router, Route, Switch } from 'react-router-dom'
import history from './history'
import Header from './Components/Header/index'
import Footer from './Components/Footer/index'
import Home from './Components/Home/index'
import Servicios from './Components/Servicios/index'
// import DetalleServicios from './Components/DetalleServicios/index'
import EstrategiaServicios from './Components/EstrategiaServicio/index'
import ArquitecturadeProcesos from './Components/ArqProcServ/index'
import SoftwareYExtensiones from './Components/SoftExtServ/index'
import ServiciosNube from './Components/NubeServicio/index'
import Contacto from './Components/Contacto/index'
import Proyectos from './Components/Proyectos/index'
import AcercaDe from './Components/AcercaDe/index'
import Comunidad from './Components/Comunidad/index'
import Typing from 'react-typing-animation'
// import Countdown from './Components/Countdown'

class App extends Component {
  render() {
    // return <Countdown />

    return (
      <div className="App">
        <Header />
        <Router history={history}>
          <Switch>
            <Route exact path="/" render={(props)=><Home {...props}></Home>} />
            <Route exact path="/nosotros" render={(props)=> <AcercaDe {...props}></AcercaDe>} />
            <Route exact path="/servicios" render={(props)=> <Servicios {...props} ></Servicios>} />
            <Route exact path="/detalleservicios/Estrategia" render={(props)=> <EstrategiaServicios {...props} ></EstrategiaServicios>} />
            <Route exact path="/detalleservicios/Arquitectura-de-procesos" render={(props)=> <ArquitecturadeProcesos {...props} ></ArquitecturadeProcesos>} />
            <Route exact path="/detalleservicios/Software-y-Extensiones" render={(props)=> <SoftwareYExtensiones {...props} ></SoftwareYExtensiones>} />
            <Route exact path="/detalleservicios/Servicios-Nube" render={(props)=> <ServiciosNube {...props} ></ServiciosNube>} />
            <Route exact path="/proyectos" render={(props)=> <Proyectos {...props}></Proyectos>} />
            <Route exact path="/comunidad" render={(props)=> <Comunidad {...props}></Comunidad>} />
            <Route exact path="/contacto" render={(props)=> <Contacto {...props}></Contacto>} />
          </Switch>
        </Router>
        <Footer />
      </div>
    )
  }
}

export default App
